import { formatDate, formatCapitalize, formatActeur } from '../formatters'

const formatType = (field, item) => {
    if(item.classeCode === "RAPPORT" && item.typeCode === "RAPP") {
        return "Rapport de commission"
    } else if(item.classeCode === "RAPINF" && item.typeCode === "RINF") {
        return "Rapport d’information"   
    } else if(item.classeCode === "RES" && item.typeCode === "RION") {
        return "Résolution"   
    } else if(item.classeCode === "AVIS" && item.typeCode === "AVIS") {
        return "Rapport pour avis"   
    } else if(item.sousTypeCode === "ENQU") {
        return "Rapport d'enquête"   
    } else if(item.sousTypeCode === "COMPA") {
        return "Texte comparatif"   
    } else if(item.classeCode === "PRJLOI" && item.typeCode === "AVCE") {
        return "Avis du Conseil d'État"   
    } else if(item.classeCode === "PRJLOI" && item.typeCode === "ETDI") {
        return "Étude d'impact"   
    } else if(item.classeCode === "PRJLOI" && item.typeCode === "ACIN") {
        return "Accord international"   
    } else if(item.classeCode === "ALLOCUTION" && item.typeCode === "ALCN") {
        return "Allocution"   
    } else if(item.classeCode === "DECLARATION" && item.typeCode === "DECL") {
        return "Engagement gouvernement (49.3)"   
    } else if(item.classeCode === "MOTION" && item.typeCode === "MION") {
        return "Motion de censure"
    } else {
        return "Divers"
    }
}

export const documentsFields = {
    dateCreation: { label: 'Date', field: 'dateCreation', format: formatDate, sort: "dateCreation" },
    type: { label: 'Type', field: 'type', format: formatType },
    auteurPrincipal: { label: 'Auteur', field: 'auteurPrincipal', include: 'auteurPrincipal', format: formatActeur },
    titre: { label: 'Titre', field: 'titrePrincipalCourt', format: formatCapitalize },
    numero: { label: 'Numéro', field: 'numNotice' }
}

export const documentsFilters = [
    { label: 'UID', value: 'uid', type: 'text' },
];
