// in src/MyError.js
import React, { useRef, useEffect } from 'react';
import { Title, useTranslate } from 'react-admin';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Report';
import History from '@mui/icons-material/History';

export const MainError = ({
  error,
  resetErrorBoundary,
  ...rest
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const originalPathname = useRef(pathname);

  useEffect(() => {
    // if (pathname !== originalPathname.current) {
    //   resetErrorBoundary();
    // }
  }, [pathname, resetErrorBoundary]);

  const translate = useTranslate();

  return (
    <Box 
      display="flex" 
      flexDirection="column" 
      alignItems="center" 
      justifyContent="center" 
      minHeight="100vh" 
      textAlign="center"
      p={2}
      bgcolor="#f0f0f0"
    >
      <Title title={translate("pos.errors.main_error")} />

      <ErrorIcon color="error" style={{ fontSize: 50 }} />
      <Typography variant="h4" gutterBottom>
        {translate("pos.errors.main_error", "An unexpected error has occurred")}
      </Typography>
      <Typography variant="subtitle1" paragraph>
        {translate("pos.errors.main_error_subtitle", "Something went wrong. Please try again later.")}
      </Typography>

      <Box 
        mt={2} 
        p={2} 
        bgcolor="white" 
        borderRadius={4}
        boxShadow={3}
        maxWidth="500px"
        textAlign="left"
      >
        <Typography variant="body1" color="textSecondary">
          <strong>{translate("Error Description")}: </strong>{translate(error.toString())}
        </Typography>
        <details style={{ whiteSpace: 'pre-wrap', marginTop: '1em' }}>
          {error.stack}
        </details>
      </Box>

      <Box mt={4}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<History />}
          onClick={() => navigate("/")}
        >
          {translate("pos.main.back", "Go to the main page")}
        </Button>
      </Box>
    </Box>
  );
};