import ActeurField from '../components/ActeurField'
import { formatDate, formatCapitalize, formatActeur } from '../formatters';

const formatType = (field, item) => {
    if (item.type === "QE"){
        return "Question écrite";
    } else if (item.type === "QOST") {
        return "Question orale"
    } else {
        return "Question au gouvernement"
    }
}

const formatDestinataire = (field, item) => {
    return item[field].libelleAbrege
}

const formatCodeCloture = (field, item) => {
    if (item.codeCloture === "REP_PUB"){
        return "Réponse reçue";
    } else if (item.codeCloture === "AU") {
        return "Retirée"
    } else if (item.codeCloture === "FM") {
        return "Caduque"
    } else {
        return "Transformée"
    }
}

export const questionsFields = {
    dateDepot: { label: 'Date', field: 'dateDepot', format: formatDate, sort: "dateDepot" },
    type: { label: 'Type', field: 'type', format: formatType },
    auteur: { label: 'Auteur', field: 'acteurRef', include: 'acteurRef', format: formatActeur },
    titre: { label: 'Titre', field: 'titre' },
    rubrique: { label: 'Catégorie', field: 'rubrique', format: formatCapitalize },
    destinataire: { label: 'Ministère destinataire', field: 'minIntRef', include: 'minIntRef', format: formatDestinataire },
    statut: { label: 'Statut', field: 'codeCloture', format: formatCodeCloture }
}

export const questionsFilters = [
    { label: 'Type', value: 'type', type: 'text' },
];
