import React from "react";
import { makeStyles } from '@mui/styles';
import { Link, useTranslate } from 'react-admin';
import FullNameField from '../../inputs/FullNameField';
import Paper from '@mui/material/Paper';
import dayjs from "dayjs";
import MailIcon from '@mui/icons-material/MailOutline';

const useStyles = makeStyles((theme) => ({
    content: {
		padding: "15px",
        display: "flex",
        flexDirection: "column",
	},
    user: {
        marginBottom: "15px",
        display: "block"
    },
    contentHeader: {
        display: "flex",
        flexDirection: "row"
    },
    position: {
        alignSelf: "center",
        display: "block",
        marginBottom: "15px",
        backgroundColor: "#5B7FF122 !important",
        padding: ".5em .75em",
        borderRadius: "6px",
        color: "#5B7FF1"
    },
    createdAt: {
        marginLeft: "auto",
        alignSelf: "center",
        fontSize: "12px",
        color: "#666",
        marginBottom: "15px",
        marginRight: "15px"
    },
    moderableContent: {
        marginBottom: "15px"
    }
}))

const ModerationReport = ({ report }) => {
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <>
            <Paper className={classes.content} elevation={1}>
                <div className={classes.contentHeader}>
                    <Link to={`/users/${report.author.hash_id}`}>
                        <div className={classes.user} style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: "rgb(91, 127, 241)", cursor: "pointer" }}>
                            <FullNameField user={report.author} />
                        </div>
                    </Link>
                    { report?.classification &&
                        <>
                            <span className={classes.createdAt}>{dayjs(report.created_at).format('DD-MM-YYYY')}</span>
                            <div className={classes.position}>
                                { report.classification }
                            </div>
                        </>
                    }
                </div>
                <div className={classes.moderableContent} dangerouslySetInnerHTML={{ __html: report.description} }></div>
            </Paper>
            <Link to={`/reports/${report.id}`} sx={{ display: "flex", alignItems: "center"}} mt={"15px"} target="_blank" rel="noreferrer">
                {translate("resources.reports.labels.contact_authors")} <MailIcon sx={{ marginLeft: "10px" }}/>
            </Link>
        </>
    )
}

export default ModerationReport;