import React, { useState, useEffect } from 'react';
import {
    TextField, Grid, Select, MenuItem, IconButton, InputLabel, FormControl, Checkbox, FormControlLabel
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';

const StyledSelect = styled(Select)(({ theme }) => ({
    marginTop: 0,
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    backgroundColor: "#5B7FF1",
    color: "#FFF",
    borderRadius: "6px",
    borderColor: "#5B7FF1",
    width: "max-content",
    '&:hover': {
        borderColor: "#5B7FF1",
    },
    '& .MuiSelect-select': {
        display: 'flex',
        alignItems: 'center',
        paddingRight: '40px !important'
    },
    '& .MuiSelect-icon': {
        right: "16px",
        color: "#FFF",
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
}));

export const ListFilter = ({ onFilterChange, initialFields }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filters, setFilters] = useState({});

    useEffect(() => {
        if (searchTerm) {
            const updatedFilters = { ...filters, search: { value: searchTerm } };
            onFilterChange(searchTerm, updatedFilters);
        }
    }, [searchTerm]);

    const handleSearchChange = (e) => {
        const newSearchTerm = e.target.value;
        setSearchTerm(newSearchTerm);
    };

    const handleSelectChange = (e) => {
        const selectedField = e.target.value;
        if (selectedField) {
            const field = initialFields.find(f => f.value === selectedField);
            setFilters({
                ...filters,
                [selectedField]: { ...field, value: field.type === 'boolean' ? false : '' }
            });
        }
    };

    const handleFilterChange = (fieldValue, newValue) => {
        setFilters(prevFilters => {
            const updatedFilters = {
                ...prevFilters,
                [fieldValue]: {
                    ...prevFilters[fieldValue],
                    value: newValue
                }
            };
            onFilterChange(searchTerm, updatedFilters);
            return updatedFilters;
        });
    };

    const handleDeleteFilter = (fieldValue) => {
        setFilters(prevFilters => {
            const { [fieldValue]: _, ...newFilters } = prevFilters;
            const updatedFilters = fieldValue === 'search' ? { ...newFilters, search: { value: '' } } : newFilters;
            onFilterChange(searchTerm, updatedFilters);
            if (fieldValue === 'search') setSearchTerm('');
            return updatedFilters;
        });
    };

    const renderFilterField = (filterValue, filter) => {
        switch (filter.type) {
            case 'date':
                return (
                    <TextField
                        label={filter.label}
                        type="date"
                        value={filter.value}
                        onChange={(e) => handleFilterChange(filterValue, e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        size='small'
                    />
                );
            case 'boolean':
                return (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filter.value}
                                onChange={(e) => handleFilterChange(filterValue, e.target.checked)}
                            />
                        }
                        label={filter.label}
                    />
                );
            default:
                return (
                    <TextField
                        label={filter.label}
                        value={filter.value}
                        onChange={(e) => handleFilterChange(filterValue, e.target.value)}
                        size='small'
                    />
                );
        }
    };

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={8}>
                <TextField
                    label="Recherche"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                    <StyledSelect
                        value=""
                        onChange={handleSelectChange}
                        displayEmpty
                        size="small"
                    >
                        <MenuItem value="" disabled>
                            <AddIcon /> Filtrer
                        </MenuItem>
                        {initialFields.map((field) => (
                            <MenuItem key={field.value} value={field.value}>
                                {field.label}
                            </MenuItem>
                        ))}
                    </StyledSelect>
                </FormControl>
            </Grid>
            {Object.keys(filters).map((filterValue) => (
                <React.Fragment key={filterValue}>
                    {filterValue !== 'search' && (
                        <Grid display={"flex"} m={"15px 0 0 15px"}>
                            <Grid item>
                                {renderFilterField(filterValue, filters[filterValue])}
                            </Grid>
                            <Grid item>
                                <IconButton onClick={() => handleDeleteFilter(filterValue)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    )}
                </React.Fragment>
            ))}
        </Grid>
    );
};
