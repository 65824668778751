import ActeurField from "../components/ActeurField";
import GroupeParlementaireField from "../components/GroupeParlementaireField";
import BooleanField from "../components/BooleanField";
import { formatChambre } from "../formatters";

const formatGroupeParlementaire = (field, item) => {
    return <GroupeParlementaireField groupeParlementaire={item[field]} />
}

const formatFullName = (field, item) => {
    return <ActeurField record={item} />
}

const formatActif = (field, item) => {
    return <BooleanField condition={item.actif} />
}

const formatCirconscription = (field, item) => {
    return item.circonscription.libelle
}

export const acteursFields = {
    'chambre': { label: "Chambre", field: "chambre", format: formatChambre },
    'fullName': { label: "Nom complet", field: "fullName", sort: "nom", format: formatFullName },
    'groupeParlementaire': { label: "Groupe politique", field: "groupeParlementaire", include: "groupeParlementaire", format: formatGroupeParlementaire },
    'circonscription': { label: "Cirsconscription", field: "circonscription" },
    'commission': { label: "Commission principale", field: "commission" },
    'themes': { label: "Sujets favoris", field: "themes" },
    'proximite': { label: "Proximité groupe", field: "proximite" },
    'actif': { label: "Actif", field: "actif", sort: "actif", format: formatActif }
};

export const acteursFilters = [
    { label: 'UID', value: 'uid', type: 'text' },
    { label: 'Né après le', value: 'dateNais.gte', type: 'date' },
    { label: 'Né avant le', value: 'dateNais.lte', type: 'date' },
];
