import React, { useCallback, useRef } from "react";
import { List, Datagrid, TextField, DateField, FunctionField, useTranslate, useListContext } from "react-admin";
import { useNavigate, matchPath, useLocation } from "react-router-dom";
import { Box, Drawer } from "@mui/material";
import { makeStyles } from '@mui/styles';
import LinkField from '../../inputs/LinkField';
import TranslationEdit from "./TranslationEdit";
import BulkStatusButton from '../../utils/BulkStatusButton';
import classnames from "classnames";
import rowStyle from "../arguments/rowStyle";
import SectionTitle from "../../utils/SectionTitle";
import dayjs from 'dayjs';
import TranslationFilter from "./TranslationFilter";
import CustomPagination from '../../utils/CustomPagination';

const useStyles = makeStyles((theme) => ({
	list: {
		flexGrow: 1
	},
	listWithDrawer: {
		marginRight: 400
	},
	content: {
		maxWidth: "18em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
	langContent: {
		textTransform: "uppercase"
	}
}));

export const flags = {
	"rm": "🇨🇭",
	"fr": "🇫🇷",
	"it": "🇮🇹",
	"en": "🇬🇧",
	"es": "🇪🇸",
	"de": "🇩🇪",
}

const TranslationBulkActionButtons = () => {
	const translate = useTranslate();
	const { selectedIds } = useListContext();

	return (
		<>
			<BulkStatusButton
				resource="translation_entries"
				selectedIds={selectedIds}
				updateObject={{ is_approved: true, approved_at: dayjs().format("YYYY-MM-DD HH:mm:ss"), status: "accepted" }}
				type="ACCEPT"
				approveMessage={translate("resources.translation_entries.fields.approve")}
				rejectMessage={translate("resources.translation_entries.fields.disapprove")}
				successMessage={translate("resources.translation_entries.notifications.moderation_success")}
				failureMessage={translate("resources.translation_entries.notifications.moderation_fail")}
			/>
			<BulkStatusButton
				resource="translation_entries"
				selectedIds={selectedIds}
				updateObject={{ is_approved: false, approved_at: dayjs().format("YYYY-MM-DD HH:mm:ss"), status: "rejected" }}
				type="REJECT"
				approveMessage={translate("resources.translation_entries.fields.approve")}
				rejectMessage={translate("resources.translation_entries.fields.disapprove")}
				successMessage={translate("resources.translation_entries.notifications.moderation_success")}
				failureMessage={translate("resources.translation_entries.notifications.moderation_fail")}
			/>
		</>
	)
};

const TranslationList = (props) => {
	const classes = useStyles();
	const translate = useTranslate();
	const navigate = useNavigate();
	const location = useLocation();
	const pageTokens = useRef({});
	const match = matchPath('/translation_entries/:id', location.pathname);
	const handleClose = useCallback(() => {
		navigate('/translation_entries');
	}, [navigate]);

	return (
		<>
			<SectionTitle
				title={translate("resources.translation_entries.section_title")}
				subtitle={translate("resources.translation_entries.section_subtitle")}
				bottomMarged
			/>
			<Box display="flex">
				<List
					className={classnames(classes.list, { [classes.listWithDrawer]: !!match })}
					title={translate("resources.translation_entries.name", { smart_count: 2 })}
					sort={{ field: "created_at", order: "DESC" }}
					filters={<TranslationFilter />}
					filter={{ countless: true }}
					disableSyncWithLocation
					actions={null}
					pagination={<CustomPagination alwaysOn />}
					queryOptions={{ meta: { pageTokens } }}
					{...props}
				>
					<Datagrid
						rowClick='edit'
						rowSx={rowStyle}
						optimized
						bulkActionButtons={<TranslationBulkActionButtons />}
						{...props}
					>
						<FunctionField
							source='origin_language'
							cellClassName={`${classes.content} ${classes.langContent}`}
							render={(record) =>
								`${record.origin_language} ${flags[record.origin_language]}`
							}
						/>
						<FunctionField
							source='target_language'
							cellClassName={`${classes.content} ${classes.langContent}`}
							render={(record) =>
								`${record.target_language} ${flags[record.target_language]}`
							}
						/>
						<TextField source='origin_content' cellClassName={classes.content} />
						<TextField source='translated_content' cellClassName={classes.content} />
						<FunctionField
							label={translate("resources.translation_entries.fields.type")}
							render={(record) =>
								record.translatable_type === "Message"
									? translate("resources.translation_entries.fields.message_type")
									: record.translatable_type === "Proposal"
										? translate("resources.translation_entries.fields.proposal_type")
										: record.translatable_type === "Group"
											? translate("resources.translation_entries.fields.debate_type")
											: record.translatable_type === "Position"
												? translate("resources.translation_entries.fields.position_type")
												: record.translatable_type === "Consultation"
													? translate("resources.translation_entries.fields.consultation_type")
													: record.translatable_type === "DebateSuggestion"
														? translate("resources.translation_entries.fields.suggestion_type")
														: ""
							}
						/>
						<FunctionField
                            render={record => (
								record.direct_url && (
									<LinkField
										source="direct_url"
										label={translate("resources.groups.fields.link")}
										content={translate("pos.main.view_link")}
									/>
								)
                            )}
                        />
						
						<DateField source='created_at' showTime={true} />
						<FunctionField
							label={translate("resources.translation_entries.fields.status")}
							render={(record) =>
								record.status === "accepted" || record.status === 0
									? translate("resources.translation_entries.fields.is_approved")
									: record.status === "rejected" || record.status === 2
										? translate("resources.translation_entries.fields.disapproved")
										: translate("resources.translation_entries.fields.pending")
							}
						/>
					</Datagrid>
				</List>
				<Drawer
					open={!!match}
					anchor='right'
					onClose={handleClose}
					sx={{ zIndex: 100 }}
				>
					{/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
					{!!match ?
						<TranslationEdit
							id={match.params.id}
							onCancel={handleClose}
							{...props}
						/> : null}
				</Drawer>
			</Box>
		</>
	);
};

export default TranslationList;
