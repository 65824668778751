import React from "react";
import { Route } from 'react-router-dom';
import { CustomRoutes } from "react-admin";
import { Search } from "./search";
import { ActeurShow } from "./acteurs";
import { QuestionShow } from "./questions";
import { OrganeShow } from "./organes";
import { DocumentShow } from "./documents";
import { DossierShow } from "./dossiers";
import { ScrutinShow } from "./scrutins";
import { AmendementShow } from "./amendements";
 
export const routes = [
    <CustomRoutes>
        <Route path="/parliament" element={<Search />} />
        <Route path="/parliament/acteurs/:acteurUid" element={<ActeurShow />} />
        <Route path="/parliament/questions/:questionUid" element={<QuestionShow />} />
        <Route path="/parliament/organes/:organeUid" element={<OrganeShow />} />
        <Route path="/parliament/documents/:documentUid" element={<DocumentShow />} />
        <Route path="/parliament/dossiers/:dossierUid" element={<DossierShow />} />
        <Route path="/parliament/scrutins/:scrutinUid" element={<ScrutinShow />} />
        <Route path="/parliament/amendements/:amendementUid" element={<AmendementShow />} />
    </CustomRoutes>
]