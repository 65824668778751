import React from 'react';
import {
    TextInput,
    Edit,
    TabbedForm,
    FormTab,
    Toolbar,
    SaveButton,
    BooleanInput,
    DateTimeInput,
    useTranslate,
} from 'react-admin';
import { useSettingsContext } from '../../utils/SettingsProvider';
import { Box } from "@mui/material";
import LinkArticleInput from '../../inputs/LinkArticleInput';
import HelperText from '../../utils/HelperText';
import PositionInput from '../../inputs/PositionInput';
import LanguageSelectInput from '../../inputs/LanguageSelectInput';
import FormSectionTitle from '../../utils/FormSectionTitle';
import dayjs from 'dayjs';

const DebateSuggestionEditToolbar = props => {
    const translate = useTranslate();

    return (
        <Toolbar {...props}>
            <SaveButton redirect="/groups" label={translate('resources.debate_suggestions.edit_title')} />
        </Toolbar>
    )
};

const DebateSuggestionEdit = (props) => {
    const translate = useTranslate();
    const config = useSettingsContext();

    const transformData = (data, { previousData }) => {
        let positionList = `${data.pro_thesis}, ${data.against_thesis}`;
        if (data.pro_thesis && data.against_thesis && data.has_neutral_thesis) {
            positionList = `${data.pro_thesis}, ${data.against_thesis}, ${(config?.settings?.vote?.name || translate("resources.groups.fields.neutral_thesis"))}`;
        }

        return {
            ...(data.has_neutral_thesis && { neutral_thesis: (config?.settings?.vote?.name || translate("resources.groups.fields.neutral_thesis")) }),
            ...(data.name !== previousData.name && { name: data.name }),
            ...(data.is_published !== previousData.is_published && {
                is_published: data.is_published,
                created_at: dayjs().format("YYYY-MM-DD HH:mm:ss")
            }),
            ...(data.is_reviewed !== previousData.is_reviewed && { is_reviewed: data.is_reviewed }),
            ...(data.language !== previousData.language && { language: data.language }),
            ...(data.is_accepted !== previousData.is_accepted && { is_accepted: data.is_accepted }),
            ...(data.ends_at !== previousData.ends_at && { ends_at: data.ends_at }),
            ...(data.tag_list !== previousData.tag_list && { tag_list: JSON.stringify(data.tag_list) }),
            ...(data.tag_objects && { tag_objects: JSON.stringify(data.tag_objects) }),
            ...(data.content_uids && { content_uids: data.content_uids.join(",") }),
            ...((previousData.pro_thesis !== data.pro_thesis)
                || (previousData.against_thesis !== data.against_thesis)
                || (previousData.has_neutral_thesis !== data.has_neutral_thesis))
            && ({ position_list: positionList })
        };
    }

    return (
        <Edit
            title={translate("resources.debate_suggestions.edit_title")}
            redirect={(resource, id, data) => "groups"}
            mutationMode="pessimistic"
            transform={transformData}
            {...props}
        >
            <TabbedForm toolbar={<DebateSuggestionEditToolbar />}>
                <FormTab label={translate('resources.groups.tabs.main')}>
                    <FormSectionTitle title={translate('resources.groups.form_title.suggestion')} />
                    <TextInput source="name" label={translate('resources.groups.labels.debate_question')} fullWidth variant="outlined" required />
                    <PositionInput />
                    {config?.settings?.translation?.enable === true &&
                        <Box mb={1}>
                            <LanguageSelectInput label={translate("resources.settings.fields.language")} />
                        </Box>
                    }
                    {config?.settings?.modules?.sources !== false &&
                        <LinkArticleInput
                            tagSource={"tag_list"}
                            tagInitialSource={"tag_list"}
                            tagFormatInitial={(initialValue) => initialValue ? JSON.parse(initialValue) : []}
                        />
                    }
                    <Box flex={1} mt={2}>
                        <DateTimeInput source="ends_at" label={translate('resources.groups.labels.ends_at')} />
                    </Box>
                    <BooleanInput source="is_published" label={translate("resources.debate_suggestions.fields.publish_suggestion")} />
                    <HelperText text={translate('resources.debate_suggestions.fields.publish_help')} />
                </FormTab>
            </TabbedForm>
        </Edit>
    )
};

export default DebateSuggestionEdit;