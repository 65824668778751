import React, { useState } from "react";
import { useDataProvider, useRedirect, useTranslate, useAuthProvider } from 'react-admin';
import { makeStyles, withStyles } from '@mui/styles';
import { generate } from "random-words";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import logo from '../images/logo-responsive.png';
import CircularProgress from '@mui/material/CircularProgress';

const LANGUAGES = [
    {
        name: "fr",
        icon: " 🇫🇷"
    },
    {
        name: "en",
        icon: " 🇬🇧"
    },
    {
        name: "es",
        icon: " 🇪🇸"
    },
    {
        name: "de",
        icon: " 🇩🇪"
    },
    {
        name: "it",
        icon: " 🇮🇹"
    },
    {
        name: "rm",
        icon: " 🇨🇭"
    }
]

const useStyles = makeStyles(theme => ({
    main: {
        height: "100vh",
        backgroundImage: "url('https://uploads-ssl.webflow.com/58c00327a3a19f2169a348cf/58c387c6b6b941e437becd29_bg-gradient-1.svg'), linear-gradient(135deg, #7956ec, #2fb9f8)",
        backgroundPosition: "center",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    formContainer: {
        boxShadow: "box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
        borderRadius: "4px",
        marginTop: "50px",
        marginBottom: "50px",
        padding: "15px",
        backgroundColor: "white",
        minWidth: "500px"
    },
    header: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "30px",
    },
    logo: {
        width: "80px",
    },
    title: {
        fontSize: "30px",
        fontWeight: "bold",
        color: "#417EC7"
    },
    form: {
        display: "flex",
        padding:"10px 40px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderBottom: "1px dotted rgba(0, 0, 0, 0.25)",
    },
    formInput: {
        margin:"10px 0 !important",
        width: "300px",
        height: "50px"
    },
    infoText: {
        color: "rgba(0, 0, 0, 0.25)",
        cursor: "pointer",
        textDecoration: "none",
        "&:hover": {
            color: "rgba(0, 0, 0, 0.15)"
        }
    },
    submitButton: {
        margin: "20px 0",
        width: "300px",
        height: "50px",
        textAlign: "center"
    },
}));

const StyledButton = withStyles({
    root: {
        marginTop: "1em !important",
        background: '#417ec7 !important',
        color: "white !important",
        "&:hover": {
            background: "#417ec799 !important"
        }
    },
  })(Button);
  

const Onboarding = () => {
    const classes = useStyles();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const authProvider = useAuthProvider();
    const redirectTo = useRedirect();
    const [applicationUrl, setApplicationUrl] = useState("");
    const [shortname, setShortname] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [allowedDomains, setAllowedDomains] = useState([]);
    const [language, setLanguage] = useState("fr");
    const [finalStep, setFinalStep] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const getAllowedDomains = (url) => {
        const parsedUrl = new URL(url);
        return [parsedUrl.protocol + "//" + parsedUrl.hostname];
    }

    const validateUrl = (event) => {
        if (event) { event.preventDefault(); }
        const parsedUrl = new URL(applicationUrl);
        const name = parsedUrl.host.split('.').slice(-2)[0];
        const displayName = name.charAt(0).toUpperCase() + name.slice(1);
        const allowedDomains = getAllowedDomains(applicationUrl);
        const randomWords = generate({ exactly: 1, wordsPerString: 2, separator: "-", maxLength: 5 });
        setShortname(name + "-" + randomWords);
        setDisplayName(displayName);
        setAllowedDomains(allowedDomains);
        setFinalStep(true);
    }

    const handleSubmit = (event) => {
        if (event) { event.preventDefault(); }
        const adminId = JSON.parse(localStorage.getItem('currentUser')).id
        setIsLoading(true);
        dataProvider.create("applications", {
            data: { 
                name: shortname,
                display_name: displayName,
                url: applicationUrl,
                allowed_domains: allowedDomains,
                admin_id: adminId,
                language: language
            }
        }).then(() => {
            authProvider.getUser().then(() => {
                setIsLoading(false);
                redirectTo("/#");
            });
        }).catch(() => {
            // Erreur mauvais mail 401
            // Rediriger vers onboarding
        })
    }

    return (
        <div className={classes.main}>
            <div className={classes.formContainer}>
                <div className={classes.header}>
                    <img className={classes.logo} src={logo} width={150} alt="Logo Logora" />
                    <div className={classes.title}>{ translate("pos.login.onboarding_title") }</div>
                </div>
                { !finalStep ?
                    <form className={classes.form} onSubmit={(event) => validateUrl(event)}>
                        <TextField required className={classes.formInput} id="applicationUrl" type="url" label={translate("pos.login.application_url")} value={applicationUrl}
                            onChange={e => setApplicationUrl(e.target.value)}
                        />
                        <StyledButton type="submit" className={classes.submitButton}>
                            { translate("pos.login.validate") }
                        </StyledButton>
                    </form>
                :
                    <form className={classes.form} onSubmit={(event) => handleSubmit(event)}>
                        <TextField required className={classes.formInput} id="displayName" type="string" label={translate("pos.login.display_name")} value={displayName}
                            onChange={e => setDisplayName(e.target.value)}
                        />
                        <TextField required className={classes.formInput} id="shortname" type="string" label={translate("pos.login.shortname")} value={shortname}
                            onChange={e => setShortname(e.target.value)}
                        />
                        <FormControl variant='outlined' className={classes.formInput}>
                            <Select
                                id='language_select'
                                value={language}
                                onChange={(e) => setLanguage(e.target.value)}
                                fullWidth
                            >
                                { LANGUAGES.map((lang, index) => 
                                    <MenuItem key={index} value={lang.name}>{lang.name.toUpperCase() + lang.icon} </MenuItem>)
                                }
                            </Select>
                        </FormControl>
                        { isLoading ?
                            <div className={classes.submitButton}>
                                <CircularProgress />
                            </div>
                        :
                            <StyledButton type="submit" className={classes.submitButton}>
                                { translate("pos.login.create") }
                            </StyledButton>
                        }
                    </form>
                }       
            </div>
        </div>
    )
}

export default Onboarding;