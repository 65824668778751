import httpClient from "../httpClient";
import queryString from "query-string";

const apiUrl = process.env.REACT_APP_TRICOTEUSES_API_URL

export const dataProvider = {
    getList: async (resource, params) => {
		try {
			const { page, perPage } = params.pagination;
			const { field, order } = params.sort || {};
			const filter = { ...params.filter };
			const includes = params.includes || [];
			const query = {
				...filter,
				...(field && { sort: `${field}.${order}` }),
				...(includes.length && { include: includes.join(',') }),
				page: page,
				perPage: perPage,
			};
			const url = resource_ => `${apiUrl}/${resource_}?${queryString.stringify(query)}`;
            switch (resource) {
				default: {
					const { headers, json } = await httpClient(url(resource), { method: "get" });
					const data = json?.data?.map((resource) => ({ ...resource, id: resource.id }));
					const total = parseInt(headers.get("total"), 10);
					return { data, total };
				}
            }
        } catch (error) { console.log(error) }
    },

	getOne: (resource, params) => {
		const url = `${apiUrl}/${resource}/${params.id}`;
		return httpClient(url, { method: "get" }).then(({ json }) => ({
			data: { ...json.data, id: json.data.uid, uid: json.data.uid },
		})).catch((error) => {});
	},
};
