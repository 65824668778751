import React, { useState } from 'react';
import { useTranslate, useDataProvider, useNotify, useGetIdentity } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { useSettingsContext } from "../utils/SettingsProvider";
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import SaveIcon from '@mui/icons-material/Save';
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const useStyles = makeStyles({
    form: { width: '300px', display: 'flex', 'flex-direction': 'column', justifyContent: "center", alignItems: "start" },
    submit: { 'margin-top': '1em', 'margin-bottom': '0.5em' },
});

const LANGUAGES = [
    {
        name: "fr",
        icon: " 🇫🇷"
    },
    {
        name: "en",
        icon: " 🇬🇧"
    },
    {
        name: "es",
        icon: " 🇪🇸"
    },
    {
        name: "de",
        icon: " 🇩🇪"
    },
    {
        name: "it",
        icon: " 🇮🇹"
    },
    {
        name: "rm",
        icon: " 🇨🇭"
    }
]

const UpdateLanguageForm = () => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const settingsContext = useSettingsContext();
    const notify = useNotify();
    const classes = useStyles();
    const { data: identity, refetch } = useGetIdentity();

    const [language, setLanguage] = useState(identity.language);

    const handleUpdateLanguage = (event) => {
        event.preventDefault();
        dataProvider.updateMe({ language: language }).then(response => {
            localStorage.setItem("currentUser", JSON.stringify(response.data.data.resource));
            notify(
                translate('resources.update_language.notifications.success'),
                {
                    type: 'info',
                    undoable: true
                }  
            );
            refetch();
        }).catch(error => {
            notify(
                translate('resources.update_language.notifications.error'),
                {
                    type: 'warning',
                    undoable: true   
                } 
            );
        });
    };

    return (
        settingsContext?.settings?.translation?.translationMethods &&  Object.keys(settingsContext?.settings?.translation?.translationMethods).length > 0 ?
            <form className={classes.form} onSubmit={handleUpdateLanguage}>
                <FormControl variant='outlined' className={classes.selectInput}>
                    <Select
                        id='language_select'
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                    >
                        {Object.keys(settingsContext.settings.translation.translationMethods).map((lang, index) => <MenuItem key={index} value={lang}>{LANGUAGES.map(e => e.name === lang && lang.toUpperCase() + e.icon )}</MenuItem>)}
                    </Select>
                </FormControl>
                <Button
                    className={classes.submit}
                    type="submit"
                    color="primary"
                    variant="contained"
                    startIcon={<SaveIcon />}
                    fullWidth={false}
                >
                    {translate("resources.update_language.fields.save")}
                </Button>
            </form>
        : null
    );
};

export default UpdateLanguageForm;