import React from 'react';
import { Menu, useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { Message, Settings, Share } from '@mui/icons-material';
import SubMenu from '../utils/SubMenu';

export const SocialNetworksMenu = () => {
    const location = useLocation();
    const pattern = /(social-networks)/;

    return (
        <SubMenu
            name="pos.menu.social_networks"
            icon={<Share />}
            isSubMenuOpen={pattern.test(location.pathname)}
        >
            <Menu.Item
                to="/social-networks/contents"
                primaryText="resources.moderable_contents.fields.name"
                leftIcon={<Message />}
            />
            <Menu.Item
                to="/social-networks/integrations"
                primaryText="resources.integrations.fields.name"
                leftIcon={<Settings />}
            />
        </SubMenu>
    );
};
