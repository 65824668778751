import React from 'react';
import { Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const BooleanField = ({ condition }) => {
    return (
        <Box display="flex" alignItems="center">
            { condition ?
                <CheckIcon color="success" />
            : 
                <CloseIcon color="error" />
            }
        </Box>
    );
};

export default BooleanField;