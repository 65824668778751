import React from 'react';
import { Box, Chip } from '@mui/material';

const GroupeParlementaireField = ({ groupeParlementaire }) => {
    return (
        <Box display="flex" alignItems="center">
            {groupeParlementaire.libelle}
        </Box>
    );
};

export default GroupeParlementaireField;