import { formatOrgane } from "../formatters";

export const interventionsFields = {
    'dateSeanceJour': { label: 'Date', field: "dateSeanceJour"},
    'typeAssemblee': { label: 'Chambre', field: "typeAssemblee"},
    'organeRef': { label: 'Organe', field: "organeRef", include: "organeRef", format: formatOrgane},
    'acteurRef': { label: 'Auteur', field: 'acteurRef', include: 'acteurRef' },
    'titre': { label: 'Nom du compte rendu', field: "titre"},
    'resume': { label: 'L\'essentiel', field: "resume"},
}

export const interventionsFilters = [
    
];
