// in src/MyMenu.js
import React from 'react';
import { Menu, useTranslate, usePermissions } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { SourceMenu } from './source';
import { ModerationMenu } from './moderation';
import { DebateMenu } from './debate';
import { ParliamentMenu } from './parliament';
import { SocialNetworksMenu } from './social_networks';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { useActiveModulesContext } from './utils/ActiveModulesContext';

const useStyles = makeStyles(() => ({
    menuItem: {
        '&:hover': {
            borderRadius: '0 6px 6px 0'
        },
        '&.RaMenuItemLink-active': {
            color: '#5B7FF1 !important',
            backgroundColor: '#5B7FF122 !important',
            borderRadius: '0 6px 6px 0',
            '& .RaMenuItemLink-icon': {
                color: '#5B7FF1 !important'
            }
        }
    }
}));

const MyMenu = () => {
    const { permissions } = usePermissions();
    const { isParliamentActive, isSocialModerationActive, isSourceActive } =
        useActiveModulesContext();
    const translate = useTranslate();
    const classes = useStyles();

    return (
        <>
            <Menu.DashboardItem
                className={classes.menuItem}
                leftIcon={<DashboardIcon />}
                primaryText={translate('pos.menu.dashboard')}
            />
            <DebateMenu />

            {isParliamentActive && <ParliamentMenu />}

            <Menu.ResourceItem
                name="users"
                primaryText={translate('resources.users.name', {
                    smart_count: 2
                })}
            />
            {isSourceActive && <SourceMenu />}

            <ModerationMenu />

            {(permissions === 'admin' || permissions === 'super_admin') && (
                <Menu.ResourceItem
                    name="settings"
                    className={classes.menuItem}
                    primaryText={translate(`resources.settings.name`, {
                        smart_count: 2
                    })}
                />
            )}
            <Menu.Item
                className={classes.menuItem}
                to="https://docs.logora.fr/"
                primaryText={translate('pos.menu.documentation')}
                leftIcon={<FindInPageIcon />}
                onClick={(e) => {
                    e.preventDefault();
                    window.open('https://docs.logora.fr/');
                }}
            />
            {isSocialModerationActive && <SocialNetworksMenu />}
        </>
    );
};

export default MyMenu;
