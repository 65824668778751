import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDataProvider } from 'react-admin';
import { Box } from '@mui/material';

export const OrganeShow = () => {
    const { organeUid } = useParams();
    const [organe, setOrgane] = useState(null);
    const dataProvider = useDataProvider();

    useEffect(() => {
        fetchElement();
    }, []);

    const fetchElement = useCallback(async () => {
        try {
            const response = await dataProvider.getOne(`organes`, {
                id: organeUid
            });
            const organe = response.data;
            if (organe) {
                setOrgane(organe);
            }
        } catch (error) {
            console.log(error);
        }
    }, [dataProvider]);

    if (!organe) return <div>Loading...</div>;

    return (
        <Box>
            {organe.uid}
        </Box>
    );
};
