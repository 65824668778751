import { useState } from "react";
import {
    TopToolbar,
    Button,
} from "react-admin";
import PersonIcon from '@mui/icons-material/Person';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

export const ListNavigation = ({ onPageChange, totals }) => {
    const [activePage, setActivePage] = useState("dossiers");
    
    const getButtonStatus = (path) => {
        return activePage === path ? activeButtonStyles : buttonStyles;
    }

    const formatTotal = (total) => {
        if (total >= 10000) { return "+10000" }
        return total
    }

    const handlePageChange = (page) => {
        setActivePage(page);
        onPageChange(page);
    }

    const buttonStyles = {
        boxShadow: "none",
        color: "#5B7FF1",
        backgroundColor: "white",
        textTransform: "capitalize",
        "&:hover": {
            color: "#5B7FF1",
            backgroundColor: "white",
            boxShadow: "none"
        },
        "> span > svg": {
            fontSize: "16px !important"
        }
    }

    const activeButtonStyles = {
        boxShadow: "none",
        backgroundColor: "#5B7FF1",
        color: "white",
        textTransform: "capitalize",
        "&:hover": {
            backgroundColor: "#5B7FF1",
            color: "white",
            boxShadow: "none"
        },
        "> span > svg": {
            fontSize: "16px !important"
        }
    }

    return (
        <TopToolbar sx={{ justifyContent: "flex-start", width: "100%", padding: "10px 10px 10px 0", flexWrap: "wrap" }}>
            <Button onClick={() => handlePageChange("dossiers")} label={`Dossiers (${formatTotal(totals.dossiers)})`} variant={"outlined"} size="medium" sx={getButtonStatus("dossiers")} startIcon={<FolderOpenIcon />} />
            <Button onClick={() => handlePageChange("acteurs")} label={`Acteurs (${formatTotal(totals.acteurs)})`} variant={"outlined"} size="medium" sx={getButtonStatus("acteurs")} startIcon={<PersonIcon/>} />
            <Button onClick={() => handlePageChange("amendements")} label={`Amendements (${formatTotal(totals.amendements)})`} variant={"outlined"} size="medium" sx={getButtonStatus("amendements")} startIcon={<AppRegistrationIcon />} />
            <Button onClick={() => handlePageChange("scrutins")} label={`Scrutins (${formatTotal(totals.scrutins)})`} variant={"outlined"} size="medium" sx={getButtonStatus("scrutins")} startIcon={<AppRegistrationIcon />} />
            <Button onClick={() => handlePageChange("debats")} label={`Débats (${formatTotal(totals.debats)})`} variant={"outlined"} size="medium" sx={getButtonStatus("debats")} startIcon={<PersonIcon/>} />
            <Button onClick={() => handlePageChange("interventions")} label={`Interventions (${formatTotal(totals.interventions)})`} variant={"outlined"} size="medium" sx={getButtonStatus("interventions")} startIcon={<AppRegistrationIcon />} />
            <Button onClick={() => handlePageChange("reunions")} label={`Réunions (${formatTotal(totals.reunions)})`} variant={"outlined"} size="medium" sx={getButtonStatus("reunions")} startIcon={<PersonIcon/>} />
            <Button onClick={() => handlePageChange("documents")} label={`Documents (${formatTotal(totals.documents)})`} variant={"outlined"} size="medium" sx={getButtonStatus("documents")} startIcon={<TurnedInNotIcon />} />
            <Button onClick={() => handlePageChange("questions")} label={`Questions (${formatTotal(totals.questions)})`} variant={"outlined"} size="medium" sx={getButtonStatus("questions")} startIcon={<QuestionMarkIcon />} />
            <Button onClick={() => handlePageChange("organes")} label={`Organes (${formatTotal(totals.organes)})`} variant={"outlined"} size="medium" sx={getButtonStatus("organes")} startIcon={<AppRegistrationIcon />} />
        </TopToolbar>
    );
}
