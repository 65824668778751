import { 
    List,
    Datagrid,
    useRecordContext,
    useTranslate,
    ChipField,
} from 'react-admin';
import Chip from "@mui/material/Chip"
import AuthorField from '../inputs/AuthorField';

const DashboardDebates = (props) => {
    const translate = useTranslate();
    return (
        <List
            disableSyncWithLocation
            resource="groups"
            filters={null} 
            pagination={null}
            actions={null} 
            sort={{ field: "messages_count", order: "DESC" }} 
            filterDefaultValues={{ "created_at.gte": props.startDate, "created_at.lte": props.endDate }}
            style={{ margin: "0 0.5rem 30px 0.5rem", maxWidth: "1184px" }}
            storeKey="dashboardGroups"
        >
            <Datagrid bulkActionButtons={null} sort={{ field: "messages_count", order: "DESC" }}>
                <ChipField source="name" style={{ background: "#5B7FF122", color:"#5B7FF1", minWidth: "150px", fontWeight: "bold" }} sortable={false} />
                <ChipField source='messages_count' style={{ background: "#FB8BED22", color:"#FB8BED", minWidth: "150px", fontWeight: "bold" }}/>
                <ChipField source='upvotes' style={{ background: "#EF8C8C22", color:"#EF8C8C", minWidth: "150px", fontWeight: "bold" }} sortable={false} />
                <AuthorField source={"group_context.author"} label={translate("resources.groups.labels.created_by")} sortable={false} />
                <CreationDateField label={translate("resources.groups.fields.created_at")} sortable={false} />
            </Datagrid>
        </List>
    )
};

const CreationDateField = () => {
    const record = useRecordContext();

	if (record?.created_at) {
        return (
            <Chip  
                label={record.created_at.substring(0, 10)} 
                style={{ background: "#FCB85922", color:"#FCB859", minWidth: "150px", fontWeight: "bold" }}
            />
        )
    } else { return null; }
}

export default DashboardDebates;