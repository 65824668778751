import React, { useState } from "react";
import { GoogleLoginButton } from '@logora/debate.auth.google_login_button';
import { useTranslate } from 'react-admin';
import { makeStyles, withStyles } from '@mui/styles';
import { InputAdornment, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const callbackUrl = process.env.REACT_APP_API_AUTH_CALLBACK;

const useStyles = makeStyles(theme => ({
    main: {
        height: "100vh",
        backgroundImage: "url('https://uploads-ssl.webflow.com/58c00327a3a19f2169a348cf/58c387c6b6b941e437becd29_bg-gradient-1.svg'), linear-gradient(135deg, #7956ec, #2fb9f8)",
        backgroundPosition: "center",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    formContainer: {
        boxShadow: "box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
        borderRadius: "4px",
        marginTop: "50px",
        marginBottom: "50px",
        padding: "15px",
        backgroundColor: "white",
        minWidth: "500px"
    },
    header: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "30px",
    },
    logo: {
        width: "80px",
    },
    title: {
        fontSize: "30px",
        fontWeight: "bold",
        color: "#417EC7"
    },
    form: {
        display: "flex",
        padding:"10px 40px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    formInput: {
        margin:"10px 0 !important",
        width: "300px",
        height: "50px"
    },
    submitButton: {
        margin: "20px 0 10px 0",
        width: "300px",
        height: "50px",
        textAlign: "center"
    },
    socialLogin: {
        padding: "10px"
    },
    socialButton: {
        marginTop: "15px",
        border: "1px solid",
        borderRadius: "4px",
        padding: "6px 8px",
        textAlign: "center"
    },
    separationContainer: {
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        fontSize: "12px",
        color: "#000",
        padding: "10px 40px",
        "&::before": {
            content: "''",
            height: "1px",
            display: "block",
            backgroundColor: "#000",
            width: "auto",
            flexGrow: "1",
            marginRight: "30px",
        },
        "&::after": {
            content: "''",
            height: "1px",
            display: "block",
            backgroundColor: "#000",
            width: "auto",
            flexGrow: "1",
            marginLeft: "30px",
        }
    },
}));

const StyledButton = withStyles({
    root: {
        marginTop: "1em !important",
        background: '#417ec7 !important',
        color: "white !important",
        "&:hover": {
            background: "#417ec799 !important"
        }
    },
  })(Button);

const Login = ({ isLoading, onSubmit }) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const classes = useStyles();
    const translate = useTranslate();
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
	const [userEmail, setUserEmail] = useState(urlParams.get("email") || "");

    const handleSubmit = (event, code = null) => {
        let userParams = {};
        if (event) { event.preventDefault(); }
        if (code) {
            userParams = {
                grant_type: "assertion",
                assertion_type: "google",
                assertion: code
            }
            onSubmit(userParams);
        } else {
            userParams = {
                grant_type: "password",
                username: userEmail,
                password: password,
            }
            onSubmit(userParams);
        }
    }

    return (
        <>
            <div className={classes.socialLogin}>
                <GoogleLoginButton 
                    text={translate("pos.login.google_login")}
                    googleClientId={googleClientId}
                    redirectUri={callbackUrl}
                    onCode={(code) => handleSubmit(null, code)}
                />
            </div>
            <div className={classes.separationContainer}>
                <div>{translate("pos.login.or_login")}</div>
            </div>
            <form className={classes.form} onSubmit={(event) => handleSubmit(event)}>
                <TextField required className={classes.formInput} id="email" type="email" label={translate("pos.login.email")} value={userEmail}
                    onChange={e => setUserEmail(e.target.value)}
                />
                <TextField required type={showPassword ? "text" : "password"} className={classes.formInput} id="password" label={translate("pos.login.password")} value={password}
                    onChange={e => setPassword(e.target.value)}
                    InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              title={ showPassword ? translate("pos.login.hide_password") : translate("pos.login.show_password") }
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                />
                 { isLoading ?
                    <div className={classes.submitButton}>
                        <CircularProgress />
                    </div>
                :
                    <StyledButton type="submit" className={classes.submitButton}>
                        { translate("pos.login.login") }
                    </StyledButton>
                }
            </form>
        </>
    )
}

export default Login;