import React from 'react';
import { Drawer } from '@mui/material';
import {
    Datagrid,
    DateField,
    FunctionField,
    List,
    NumberField,
    TextField,
    useTranslate
} from 'react-admin';
import ArgumentEdit from '../../debate/arguments/ArgumentEdit';
import rowStyle from '../../debate/arguments/rowStyle';
import CustomPagination from '../../utils/CustomPagination';
import ModerationBulkActionButtons from '../../utils/ModerationBulkActionsButtons';
import SectionTitle from '../../utils/SectionTitle';
import ModerableContentFilter from './ModerableContentFilter';
import { useCallback } from 'react';
import { useNavigate, matchPath, matchRoutes } from 'react-router-dom';
import { ModerableSourceField } from '../moderable_sources/ModerableSourceField';
import LinkField from '../../inputs/LinkField';
import AuthorField from '../AuthorField';

export const ModerableContentsList = () => {
    const translate = useTranslate();
    const navigate = useNavigate();
    const handleClose = useCallback(() => {
        navigate(`/${window.location.pathname.split('/')[1]}`);
    }, [navigate, window.location.pathname]);
    const routes = [{ path: '/moderable_contents/:id' }];
    const [{ route }] = matchRoutes(routes, window.location) || [
        { path: '/messages' }
    ];
    const match = route && matchPath(route.path, window.location.pathname);

    return (
        <>
            <SectionTitle
                title={translate('resources.moderable_contents.section_title')}
                subtitle={translate(
                    'resources.moderable_contents.section_subtitle'
                )}
            />

            <List
                resource="moderable_contents"
                title="resources.moderable_contents.section_title"
                filters={<ModerableContentFilter />}
                filterDefaultValues={{ is_deleted: false }}
                sort={{ field: 'created_at', order: 'DESC' }}
                disableSyncWithLocation
                pagination={<CustomPagination alwaysOn />}
                sx={{ marginRight: '6px' }}
            >
                <Datagrid
                    rowSx={rowStyle}
                    optimized
                    bulkActionButtons={
                        <ModerationBulkActionButtons
                            resourceName="moderable_contents"
                            get_moderation_entry={undefined}
                        />
                    }
                >
                    <TextField source="id" label="ID" />

                    <AuthorField label="Author" />

                    <TextField
                        source="content"
                        label=""
                        sx={{ display: 'block', width: 400 }}
                    />

                    <ModerableSourceField
                        label={translate(
                            'resources.moderable_contents.fields.source'
                        )}
                    />

                    <LinkField
                        source="url"
                        content={translate('pos.main.view_link')}
                        label="resources.moderable_contents.fields.url"
                    />

                    <NumberField
                        source="moderation_entry.moderation_score"
                        label={translate(
                            'resources.messages.fields.moderation_score'
                        )}
                        options={{ maximumFractionDigits: 2 }}
                    />
                    <DateField
                        source="created_at"
                        label={translate(
                            'resources.moderable_contents.fields.created_at'
                        )}
                        showTime
                    />
                    <FunctionField
                        label={translate(
                            'resources.moderable_contents.fields.status'
                        )}
                        render={(record: Record<string, any>) =>
                            record?.moderation_entry?.status === 'rejected'
                                ? translate(
                                      'resources.messages.status.rejected'
                                  )
                                : record?.moderation_entry?.status ===
                                  'accepted'
                                ? translate(
                                      'resources.messages.status.accepted'
                                  )
                                : translate('resources.messages.status.pending')
                        }
                    />
                </Datagrid>
            </List>
            <Drawer
                open={!!match}
                anchor="right"
                onClose={handleClose}
                sx={{ zIndex: 100 }}
            >
                {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                {!!match && (
                    <ArgumentEdit id={match.params.id} onCancel={handleClose} />
                )}
            </Drawer>
        </>
    );
};
