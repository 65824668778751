import React from 'react';
import {
    Filter,
    DateInput,
    SearchInput,
    SelectInput,
    useTranslate
} from 'react-admin';

const ModerableContentFilter = (props: any) => {
    const translate = useTranslate();

    return (
        <Filter {...props}>
            <SearchInput source="query" alwaysOn variant={'outlined'} />

            <DateInput
                source="created_at_gte"
                alwaysOn
                label={translate('resources.filters.from_date')}
                variant={'outlined'}
            />
            <DateInput
                source="created_at_lte"
                alwaysOn
                label={translate('resources.filters.to_date')}
                variant={'outlined'}
            />
            <SelectInput
                style={{ marginBottom: '0' }}
                source="status"
                resettable
                choices={[
                    {
                        id: 'accepted',
                        name: translate('resources.messages.status.accepted')
                    },
                    {
                        id: 'pending',
                        name: translate('resources.messages.status.pending')
                    },
                    {
                        id: 'rejected',
                        name: translate('resources.messages.status.rejected')
                    }
                ]}
                alwaysOn
                variant={'outlined'}
            />
        </Filter>
    );
};

export default ModerableContentFilter;
