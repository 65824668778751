import React, { useState } from 'react';
import { TextField, TextInput, SimpleForm, Edit, FunctionField, Labeled, useTranslate, SelectInput } from 'react-admin';
import { flags } from './TranslationList';
import { Grid, Stack, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TranslationEditToolbar from './TranslationEditToolbar';

const TranslationEdit = ({ id, onCancel }) => {
    const [content, setContent] = useState();
    const [originLanguage, setOriginLanguage] = useState();
    const translate = useTranslate();

    return (
        <Edit id={id}>
            <Stack direction="row" p={2}>
                <Typography variant="h6" flex="1">
                    { translate('resources.messages.labels.detail') }
                </Typography>
                <IconButton onClick={onCancel} size="small">
                    <CloseIcon />
                </IconButton>
            </Stack>
            <SimpleForm
                resource="translation_entries"
                toolbar={<TranslationEditToolbar translatedContent={content} originLanguage={originLanguage} />}
                sx={{ maxWidth: '600px !important' }}
            >
                <Grid container rowSpacing={1} mb={1}>
                    <Grid item xs={12}>
                        <Labeled label={translate('resources.translation_entries.fields.target_language')}>
                            <FunctionField
                                source='target_language'
                                sx={{ textTransform: "uppercase" }}
                                render={(record) =>
                                    record && `${record.target_language} ${flags[record.target_language]}`
                                }
                            />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12}>
                        <Labeled label={translate('resources.translation_entries.fields.origin_content')}>
                            <TextField source="origin_content" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12}>
                        <Labeled label={translate('resources.translation_entries.fields.translation_method')}>
                            <TextField source="translation_method" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12}>
                        <Labeled label={translate('resources.translation_entries.fields.type')}>
                            <FunctionField
                                render={(record) =>
                                    record?.translatable_type === "Message"
                                        ? translate("resources.translation_entries.fields.message_type")
                                        : record.translatable_type === "Proposal"
                                            ? translate("resources.translation_entries.fields.proposal_type")
                                            : record.translatable_type === "Group"
                                                ? translate("resources.translation_entries.fields.debate_type")
                                                : record.translatable_type === "Position"
                                                    ? translate("resources.translation_entries.fields.position_type")
                                                    : record.translatable_type === "Consultation"
                                                        ? translate("resources.translation_entries.fields.consultation_type")
                                                        : record.translatable_type === "DebateSuggestion"
                                                            ? translate("resources.translation_entries.fields.suggestion_type")
                                                            : ""
                                }
                            />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12}>
                        <SelectInput
                            source="origin_language"
                            choices={[
                                { id: 'fr', name: `FR ${flags["fr"]}` },
                                { id: 'en', name: `EN ${flags["en"]}` },
                                { id: 'de', name: `DE ${flags["de"]}` },
                                { id: 'it', name: `IT ${flags["it"]}` },
                                { id: 'rm', name: `RM ${flags["rm"]}` },
                            ]}
                            onChange={(e) => setOriginLanguage(e.target.value)}
                            helperText={false}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            multiline
                            fullWidth
                            sx={{ margin: "10px 0" }}
                            source="translated_content"
                            onChange={(e) => setContent(e.target.value)}
                            helperText={false}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FunctionField
                            label={translate("resources.translation_entries.fields.status")}
                            render={(record) =>
                                record?.is_approved === true
                                    ? translate("resources.translation_entries.fields.is_approved")
                                    : record.is_approved === false && record.approved_at === null
                                        ? translate("resources.translation_entries.fields.pending")
                                        : translate("resources.translation_entries.fields.disapproved")
                            }
                        />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    )
};

export default TranslationEdit;