import React, { useState } from "react";
import { GoogleLoginButton } from '@logora/debate.auth.google_login_button';
import { useTranslate } from 'react-admin';
import { makeStyles, withStyles } from '@mui/styles';
import { v4 as uuidv4 } from 'uuid';
import { InputAdornment, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const callbackUrl = process.env.REACT_APP_API_AUTH_CALLBACK;

const useStyles = makeStyles(theme => ({
    main: {
        height: "100vh",
        backgroundImage: "url('https://uploads-ssl.webflow.com/58c00327a3a19f2169a348cf/58c387c6b6b941e437becd29_bg-gradient-1.svg'), linear-gradient(135deg, #7956ec, #2fb9f8)",
        backgroundPosition: "center",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    formContainer: {
        boxShadow: "box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
        borderRadius: "4px",
        marginTop: "50px",
        marginBottom: "50px",
        padding: "15px",
        backgroundColor: "white",
        minWidth: "500px"
    },
    header: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "30px",
    },
    logo: {
        width: "80px",
    },
    title: {
        fontSize: "30px",
        fontWeight: "bold",
        color: "#417EC7"
    },
    form: {
        display: "flex",
        padding:"10px 40px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    formInput: {
        margin:"10px 0 !important",
        width: "100%",
        height: "50px"
    },
    infoText: {
        color: "#000",
        cursor: "pointer",
        "&:hover": {
            color: "#000"
        }
    },
    submitButton: {
        margin: "20px 0",
        width: "300px",
        height: "50px",
        textAlign: "center"
    },
    socialLogin: {
        padding: "10px"
    },
    socialButton: {
        marginTop: "15px",
        border: "1px solid",
        borderRadius: "4px",
        padding: "6px 8px",
        textAlign: "center"
    },
    footerLinks: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "10px 40px"
    },
    separationContainer: {
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        fontSize: "12px",
        color: "#000",
        padding: "10px 40px",
        "&::before": {
            content: "''",
            height: "1px",
            display: "block",
            backgroundColor: "#000",
            width: "auto",
            flexGrow: "1",
            marginRight: "30px",
        },
        "&::after": {
            content: "''",
            height: "1px",
            display: "block",
            backgroundColor: "#000",
            width: "auto",
            flexGrow: "1",
            marginLeft: "30px",
        }
    },
    nameInput: {
        margin:"10px 0 !important",
        marginLeft: "10px !important",
        width: "100%",
        height: "50px"
    },
    nameContainer: {
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "space-between"
    }
}));

const StyledButton = withStyles({
    root: {
        marginTop: "1em !important",
        background: '#417ec7 !important',
        color: "white !important",
        "&:hover": {
            background: "#417ec799 !important"
        }
    },
  })(Button);

const SignUp = ({ isLoading, onSubmit }) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const classes = useStyles();
    const translate = useTranslate();
    const [showPassword, setShowPassword] = useState("");
    const [password, setPassword] = useState("");
    const [userFirstName, setUserFirstName] = useState(urlParams.get("first_name") || "");
	const [userLastName, setUserLastName] = useState(urlParams.get("last_name") || "");
	const [userEmail, setUserEmail] = useState(urlParams.get("email"));

    const handleSubmit = (event, code = null) => {
        let userParams = {};
        if (event) { event.preventDefault(); }
        if (code) {
            userParams = {
                grant_type: "assertion",
                assertion_type: "google",
                assertion: code
            }
            onSubmit(userParams);
        } else {
            const userParams = {
                uid: uuidv4(),
                email: userEmail,
                first_name: userFirstName,
                last_name: userLastName,
                password: password,
                is_admin: true,
            };
            let assertion  = window.btoa(JSON.stringify(userParams));
            let assertionParams = {
                grant_type: "assertion",
                assertion: assertion,
                assertion_type: "form",
            };
            onSubmit(assertionParams);
        }
    }

    return (
        <>
            <>
                <div className={classes.socialLogin}>
                    <GoogleLoginButton 
                        text={translate("pos.login.google_signup")}
                        googleClientId={googleClientId}
                        redirectUri={callbackUrl}
                        onCode={(code) => handleSubmit(null, code)}
                    />
                </div>
                <div className={classes.separationContainer}>
                    <div>{translate("pos.login.or_signup")}</div>
                </div>
            
                <form className={classes.form} onSubmit={(event) => handleSubmit(event)}>
                    <div className={classes.nameContainer}>
                        <TextField required type='string' className={classes.formInput} id='firstName' label={translate("pos.login.first_name")} value={userFirstName}
                            onChange={(e) => setUserFirstName(e.target.value)}
                        />
                        <TextField required type='string' className={classes.nameInput} id='lastName' label={translate("pos.login.last_name")} value={userLastName}
                            onChange={(e) => setUserLastName(e.target.value)}
                        />
                    </div>
                    <TextField required className={classes.formInput} id="email" type="email" label={translate("pos.login.email")} value={userEmail}
                        onChange={e => setUserEmail(e.target.value)}
                    />
                    <TextField required type={showPassword ? "text" : "password"} className={classes.formInput} id="password" label={translate("pos.login.password")} value={password}
                        onChange={e => setPassword(e.target.value)}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  title={ showPassword ? translate("pos.login.hide_password") : translate("pos.login.show_password") }
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                    />
                    { isLoading ?
                        <div className={classes.submitButton}>
                            <CircularProgress />
                        </div>
                    :
                        <StyledButton type="submit" className={classes.submitButton}>
                            { translate("pos.login.signup") }
                        </StyledButton>
                    }
                </form>
            </>
        </>
    )
}

export default SignUp;