import React, { useCallback, useRef } from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    FunctionField,
    BooleanField,
    TopToolbar,
    useRecordContext,
    useTranslate,
    useListContext,
    RichTextField
} from 'react-admin';
import { useLocation, useNavigate, matchPath, matchRoutes } from 'react-router-dom';
import { Box, Link, Drawer } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { UserReferenceField } from '../../users';
import VerifiedIcon from '@mui/icons-material/Verified';
import RecordLinkField from "../../inputs/RecordLinkField";
import LinkField from '../../inputs/LinkField';
import ModerationBulkActionButtons from '../../utils/ModerationBulkActionsButtons';
import ArgumentFilter from './ArgumentFilter';
import ArgumentEdit from './ArgumentEdit';
import rowStyle from './rowStyle';
import SectionTitle from '../../utils/SectionTitle';
import classnames from 'classnames';
import CustomPagination from '../../utils/CustomPagination';
import ExportButton from '../../utils/ExportButton';

const useStyles = makeStyles(_theme => ({
    list: {
        flexGrow: 1
    },
    listWithDrawer: {
        marginRight: 400
    },
    content: {
        maxWidth: '18em',
    },
    rowCentered: {
        textAlign: "center",
        color: "#5B7FF1"
    },
}));

const ArgumentListActions = () => {
    const { filterValues, sort } = useListContext();

    return (
        <TopToolbar>
            <ExportButton dataName={"Message"} filters={filterValues} sort={sort} />
        </TopToolbar>
    );
}

const ArgumentList = props => {
    const classes = useStyles();
    const translate = useTranslate();
    const navigate = useNavigate();
    const location = useLocation();
    const pageTokens = useRef({});
    const routes = [{ path: "/messages/:id" }, { path: "/comments/:id" }];
    const [{ route }] = matchRoutes(routes, location) || [{ path: "/messages" }];
    const match = route && matchPath(route.path, location.pathname);
    const handleClose = useCallback(() => {
        navigate(`/${location.pathname.split('/')[1]}`);
    }, [navigate, location.pathname]);

    // Accessors to reduce the usage of ternary in JSX.
    const filtersGroupType = location.pathname.includes("messages") ? "groups" : "sources";
    const group_type = location.pathname.includes("messages") ? "Group" : "Source";
    const resourceName = location.pathname.includes("messages") ? "messages" : "comments";

    return (
        <>
            <SectionTitle
                title={translate("resources.messages.section_title")}
                subtitle={translate("resources.messages.section_subtitle")}
            />
            <Box display="flex">
                <List
                    className={classnames(classes.list, { [classes.listWithDrawer]: !!match })}
                    title={translate('resources.messages.name', { smart_count: 2 })}
                    filters={<ArgumentFilter groupType={filtersGroupType} />}
                    filter={{ countless: true, group_type }}
                    filterDefaultValues={{ is_deleted: false }}
                    sort={{ field: 'created_at', order: 'DESC' }}
                    disableSyncWithLocation
				    queryOptions={{ meta: { pageTokens } }}
                    pagination={<CustomPagination alwaysOn />}
                    actions={<ArgumentListActions />}
                    {...props}
                >
                    <Datagrid
                        rowClick="edit"
                        rowSx={rowStyle}
                        optimized
                        {...props}
                        bulkActionButtons={<ModerationBulkActionButtons resourceName={resourceName}/>}
                    >
                        <TextField source="id" label="ID" />
                        <UserReferenceField source="author" targetId="author.hash_id" />
                        <GroupField label={translate("resources.messages.labels.origin")} />
                        <LinkField
                            source="direct_url"
                            label={translate("resources.groups.fields.link")}
                            content={translate("pos.main.view_link")}
                        />
                        <FunctionField
                            render={record => (
                                <RichTextField
                                    source="content"
                                    sx={{ display: "block", width: 400, ...(record.has_source && { fontWeight: "bold" }) }}
                                />
                            )}
                        />
                        <NumberField
                            source="score"
                            label={translate('resources.messages.fields.score')}
                            options={{ maximumFractionDigits: 2 }}
                        />
                        <NumberField
                            source="moderation_entry.moderation_score"
                            label={translate('resources.messages.fields.moderation_score')}
                            options={{ maximumFractionDigits: 2 }}
                        />
                        <NumberField
                            source="upvotes"
                            label={translate('resources.messages.fields.upvotes')}
                        />
                        <BooleanField
                            source={"is_reply"}
                            FalseIcon={null}
                            style={{ color: "#4CAF50" }}
                        />
                        <DateField source="created_at" showTime={true} />
                        <SelectedField label={translate("resources.messages.fields.selected")} />
                        <FunctionField
                            label={translate("resources.messages.fields.status")}
                            render={record =>
                                record?.moderation_entry?.status === 'rejected'
                                    ? translate('resources.messages.status.rejected')
                                    : record?.moderation_entry?.status === 'accepted'
                                    ? translate('resources.messages.status.accepted')
                                    : translate('resources.messages.status.pending')
                            }
                        />
                    </Datagrid>
                </List>
                <Drawer
                    open={!!match}
                    anchor="right"
                    onClose={handleClose}
                    sx={{ zIndex: 100 }}
                >
                    {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                    {!!match && (
                        <ArgumentEdit
                            id={match.params.id}
                            onCancel={handleClose}
                        />
                    )}
                </Drawer>
            </Box>
        </>
    );
};

const GroupField = () => {
    const record = useRecordContext();
    const translate = useTranslate();

    if (record?.group_type !== "Source")
        return <RecordLinkField resource={"groups"} targetId="group.slug" source="group_name" />;
    if (!record.group.source_url) return record.group_name;
    return (
        <Link
            href={record.group.source_url}
            target='_blank'
            rel={"noopener noreferrer"}
            style={{ "textDecoration": "none" }}
            label={translate('resources.groups.name', { smart_count: 1 })}
        >
            {record.group_name}
        </Link>
    );
};

const SelectedField = () => {
    const record = useRecordContext();
    const classes = useStyles();

    if (record?.score !== 99) return null;
    return (
        <div className={classes.rowCentered}>
            <VerifiedIcon />
        </div>
    );
};

export default ArgumentList;
