import queryString from 'query-string';
import httpClient from '../httpClient';

const apiUrl = process.env.REACT_APP_SOCIAL_MODERATOR_URL;

export const dataProvider = {
    getList: async (resource, params) => {
        try {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const orderSign = order === 'ASC' ? '+' : '-';
            const filter = { ...params.filter };
            if (Object.keys(filter).length > 0) {
                const renameFilter = (key, newKey) => {
                    if (filter[key]) {
                        filter[newKey] = filter[key];
                        delete filter[key];
                    }
                };
                renameFilter('created_at_gte', 'created_at.gte');
                renameFilter('created_at_lte', 'created_at.lte');
                renameFilter('score_gte', 'score.gte');
                renameFilter('score_lte', 'score.lte');
            }
            const query = {
                ...filter,
                sort: orderSign + field,
                page: page,
                per_page: perPage,
                next_page_token: params.meta?.pageTokens?.current?.[page]
            };
            const url = (resource_) =>
                `${apiUrl}/${resource_}?${queryString.stringify(query)}`;
            const hasCursorPagination =
                'cursor_pagination' in filter || 'countless' in filter;

            const { headers, json } = await httpClient(url(resource), {
                method: 'get'
            });
            const data = json;
            const { pageTokens } = params.meta || {};
            const nextPageToken = json.next_page_token;
            if (nextPageToken && pageTokens) {
                pageTokens.current = {
                    ...pageTokens.current,
                    [page + 1]: nextPageToken
                };
            }
            const pageInfo = !hasCursorPagination
                ? undefined
                : {
                      hasPreviousPage: !!pageTokens?.current[page],
                      hasNextPage: data.length === perPage && nextPageToken
                  };
            const total = hasCursorPagination
                ? undefined
                : parseInt(headers.get('total'), 10);
            return { data, total, pageInfo };
        } catch (error) {}
    },

    getOne: (resource, params) => {
        const url = `${apiUrl}/${resource}/${params.id}`;
        return httpClient(url, { method: 'get' })
            .then(({ json }) => ({
                data: json
            }))
            .catch((error) => {
                throw error;
            });
    },

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids })
        };
        const url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;
        return httpClient(url, { method: 'get' })
            .then(({ json }) => ({ data: json.data }))
            .catch((error) => {});
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        let orderSign = order === 'ASC' ? '+' : '-';
        const query = {
            sort: orderSign + field,
            page: page,
            per_page: perPage,
            [params.target]: params.id
        };
        const url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;

        return httpClient(url, { method: 'get' })
            .then(({ headers, json }) => ({
                data: json.data.resource.map((resource) => ({
                    ...resource,
                    id: resource.slug,
                    identifier: resource.id
                })),
                total: parseInt(headers.get('total'), 10)
            }))
            .catch((error) => {});
    },

    update: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'patch',
            body: params.data
        })
            .then(({ json }) => ({
                data: { ...json, id: params.id }
            }))
            .catch((error) => {});
    },

    updateMany: (resource, params) => {
        const ids = params.ids.slice(0, params.ids.length - 1);
        ids.forEach((id) => {
            httpClient(`${apiUrl}/${resource}/${id}`, {
                method: 'patch',
                body: JSON.stringify(params.data)
            });
        });
        return httpClient(
            `${apiUrl}/${resource}/${params.ids[params.ids.length - 1]}`,
            {
                method: 'patch',
                body: JSON.stringify(params.data)
            }
        )
            .then(({ json }) => ({
                data: params.ids
            }))
            .catch((error) => {});
    },

    createMany: (resource, params) => {
        const ids = params.ids.slice(0, params.ids.length - 1);
        ids.forEach((id) => {
            params.data['email'] = id;
            httpClient(`${apiUrl}/${resource}`, {
                method: 'post',
                body: JSON.stringify(params.data)
            });
        });
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'post',
            body: JSON.stringify({
                email: params.ids.slice(
                    params.ids.length - 1,
                    params.ids.length
                )[0],
                role: params.data.role
            })
        })
            .then(({ json }) => ({
                data: params.ids
            }))
            .catch((error) => {});
    },

    create: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'post',
            body: JSON.stringify(params.data)
        })
            .then(({ json }) => ({
                data: { id: json.data.slug }
            }))
            .catch((error) => {});
    },

    delete: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'delete'
        })
            .then(({ json }) => ({
                data: {}
            }))
            .catch((error) => {
                console.log(error);
            });
    },

    deleteMany: (resource, params) => {
        const ids = params.ids.slice(0, params.ids.length - 1);
        ids.forEach((resourceId) => {
            httpClient(`${apiUrl}/${resource}/${resourceId}`, {
                method: 'delete'
            });
        });
        return httpClient(
            `${apiUrl}/${resource}/${params.ids[params.ids.length - 1]}`,
            {
                method: 'delete'
            }
        )
            .then(({ json }) => ({
                data: params.ids
            }))
            .catch((error) => {});
    }
};
