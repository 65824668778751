import React, { useCallback, useRef } from 'react';
import classnames from 'classnames';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    FunctionField,
    useListContext,
    TopToolbar,
    ChipField,
    useTranslate
} from 'react-admin';
import { matchPath, useNavigate, useLocation } from 'react-router-dom';
import { Box, Drawer } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { UserReferenceField } from '../../users';
import ProposalFilter from './ProposalFilter';
import ProposalEdit from './ProposalEdit';
import RecordLinkField from "../../inputs/RecordLinkField";
import LinkField from "../../inputs/LinkField";
import ModerationBulkActionButtons from '../../utils/ModerationBulkActionsButtons';
import rowStyle from '../arguments/rowStyle';
import SectionTitle from '../../utils/SectionTitle';
import Typography from '@mui/material/Typography';
import CustomPagination from '../../utils/CustomPagination';
import ExportButton from '../../utils/ExportButton';

const useStyles = makeStyles(theme => ({
    list: {
        flexGrow: 1
    },
    listWithDrawer: {
        marginRight: 400
    },
    content: {
        maxWidth: '18em',
    },
    header: {
        color: "#5B7FF1",
        marginBottom: "1em !important",
        textAlign: "left !important"
    }
}));

const Empty = () => {
    const classes = useStyles();
    return (
        <>
            <Box display={"flex"} flexDirection={"column"} justifyContent={"flex-start"} textAlign="center" m={1}>
                <Typography variant="h6" component="h6" className={classes.header}>Tutoriel consultations</Typography>
                <iframe title="video-5" width="560" height="315" src="https://www.youtube.com/embed/fIbeQ_V5YZY" style={{ border: 0 }} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Box>
        </>
    )
};

const ProposalListActions = () => {
    const { sort, filterValues } = useListContext();
    return (
        <TopToolbar>
            <ExportButton dataName={"Proposal"} sort={sort} filers={filterValues} />
        </TopToolbar>
    );
}

const ProposalList = props => {
    const classes = useStyles();
    const translate = useTranslate();
    const navigate = useNavigate();
    const location = useLocation();
    const pageTokens = useRef({});
    const match = matchPath('/proposals/:id', location.pathname);
    const handleClose = useCallback(() => {
        navigate('/proposals');
    }, [navigate]);

    return (
        <>
            <SectionTitle
                title={translate("resources.proposals.section_title")}
                subtitle={translate("resources.proposals.section_subtitle")}
            />
            <Box display="flex">
                <List
                    className={classnames(classes.list, { [classes.listWithDrawer]: !!match })}
                    title={translate('resources.proposals.name', { smart_count: 2 })}
                    filters={<ProposalFilter />}
                    filter={{ countless: true }}
                    sort={{ field: 'created_at', order: 'DESC' }}
                    actions={<ProposalListActions />}
                    empty={<Empty />}
                    disableSyncWithLocation
                    pagination={<CustomPagination alwaysOn />}
                    queryOptions={{ meta: { pageTokens } }}
                    {...props}
                >
                    <Datagrid rowClick="edit" rowSx={rowStyle} optimized {...props} bulkActionButtons={<ModerationBulkActionButtons resourceName={"proposals"} />}>
                        <TextField source="id" label="ID" />
                        <UserReferenceField source="author" targetId="author.hash_id" />
                        <LinkField source='direct_url' label={translate("resources.groups.fields.link")} content={translate("pos.main.view_link")} />
                        <RecordLinkField resource={"consultations"} targetId="consultation.slug" source="consultation.title" />
                        <TextField source="title" cellClassName={classes.content} />
                        <TextField source="content" cellClassName={classes.content} />
                        <ChipField variant='outlined' source='tag.name' cellClassName={classes.content} label={translate('resources.proposals.fields.theme')} />
                        <NumberField source="moderation_entry.moderation_score" label={translate('resources.messages.fields.moderation_score')} options={{ maximumFractionDigits: 2 }} />
                        <DateField source="created_at" showTime={true} />
                        <FunctionField label="Statut" render={record => record.moderation_entry?.status === 'rejected' ? translate('resources.messages.status.rejected') : record.moderation_entry?.status === 'accepted' ? translate('resources.messages.status.accepted') : translate('resources.messages.status.pending')} />
                    </Datagrid>
                </List>
                <Drawer
                    open={!!match}
                    anchor="right"
                    onClose={handleClose}
                    sx={{ zIndex: 100 }}
                >
                    {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                    {!!match && (
                        <ProposalEdit
                            id={match.params.id}
                            onCancel={handleClose}
                        />
                    )}
                </Drawer>
            </Box>
        </>
    );
};

export default ProposalList;
