import React from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableFooter,
    TableSortLabel,
    TablePagination,
    Paper,
    Typography,
} from '@mui/material';
import { Loading, useTranslate, useRedirect } from 'react-admin';
import makeStyles from '@mui/styles/makeStyles';
import { visuallyHidden } from '@mui/utils';

const useStyles = makeStyles((theme) => ({
    tableHeader: {
        borderColor: "#5B7FF1",
    },
    tableHeaderCell: {
        color: "black",
        fontWeight: 'bold',
        padding: "1em",
        cursor: "pointer"
    },
    tableBody: {
        '& tr:nth-of-type(odd)': {
            backgroundColor: "rgba(0, 0, 0, 0.04) !important",
        },
    },
    tableCell: {
        padding: "0.75em"
    },
    hoverRow: {
        '&:hover': {
            backgroundColor: "rgba(0, 0, 0, 0.10) !important",
            cursor: 'pointer'
        },
    }
}));

const List = ({ resource, data, fields, currentPage, total, loading, onPageChange, onSort, sort }) => {
    const classes = useStyles();
    const translate = useTranslate();
    const redirectTo = useRedirect();

    const handleRowClick = (item) => {
        redirectTo(`/parliament/${resource}/${item.uid}`);
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow className={classes.tableHeader}>
                        {Object.keys(fields).map((key) => (
                            <TableCell
                                key={fields[key].field}
                                className={classes.tableHeaderCell}
                                sortDirection={sort.name === key ? sort.order : false}
                            >
                                <TableSortLabel
                                    active={sort.name === key}
                                    direction={sort.order}
                                    onClick={() => onSort(resource, key, fields[key])}
                                >
                                    {fields[key].label}
                                    {sort.name === key ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {sort.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                    {data.map((item, rowIndex) => (
                        <TableRow
                            key={rowIndex}
                            className={classes.hoverRow}
                            onClick={() => handleRowClick(item)}
                        >
                            {Object.keys(fields).map((key) => (
                                <TableCell key={fields[key].field} className={classes.tableCell}>
                                    { fields[key].format ?
                                        fields[key].format(fields[key].field, item)
                                        :
                                        item[fields[key].field]?.toString() || '-'
                                    }
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow align='left' className={classes.filterRow}>
                        <TablePagination
                            page={currentPage - 1}
                            count={total}
                            labelRowsPerPage={translate("pos.main.rows_per_page")}
                            rowsPerPage={10}
                            rowsPerPageOptions={[]}
                            onPageChange={(event, newPage) => { onPageChange(newPage + 1) }}
                            labelDisplayedRows={({ from, to, count }) => {
                                return `${from}-${to} ${translate("pos.main.from_to_pagination")} ${count}`
                            }}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
};

export default List;