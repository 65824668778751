import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDataProvider } from 'react-admin';
import { Box } from '@mui/material';

export const DocumentShow = () => {
    const { documentUid } = useParams();
    const [document, setDocument] = useState(null);
    const dataProvider = useDataProvider();

    useEffect(() => {
        fetchElement();
    }, []);

    const fetchElement = useCallback(async () => {
        try {
            const response = await dataProvider.getOne(`documents`, {
                id: documentUid
            });
            const document = response.data;
            if (document) {
                setDocument(document);
            }
        } catch (error) {
            console.log(error);
        }
    }, [dataProvider]);

    if (!document) return <div>Loading...</div>;

    return (
        <Box>
            {document.uid}
        </Box>
    );
};
