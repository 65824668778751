import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDataProvider } from 'react-admin';
import { Box } from '@mui/material';
import ActeurField from '../components/ActeurField';

export const ActeurShow = () => {
    const { acteurUid } = useParams();
    const [acteur, setActeur] = useState(null);
    const dataProvider = useDataProvider();

    useEffect(() => {
        fetchElement();
    }, []);

    const fetchElement = useCallback(async () => {
        try {
            const response = await dataProvider.getOne(`acteurs`, {
                id: acteurUid
            });
            const acteur = response.data;
            if (acteur) {
                setActeur(acteur);
            }
        } catch (error) {
            console.log(error);
        }
    }, [dataProvider]);

    if (!acteur) return <div>Loading...</div>;

    return (
        <Box>
            <ActeurField record={acteur} />
        </Box>
    );
};
