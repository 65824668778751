import React, { useState } from 'react';
import { OAuth2Button } from '@logora/debate.auth.oauth2_button';
import { Confirm, useRefresh, useTranslate, useUpdate } from 'react-admin';
import { Button, Stack, Switch, Typography } from '@mui/material';
import { Integration, IntegrationStatusEnum } from './integration';
import theme from '../../theme';

interface IntegrationActionsProps {
    integrationId?: Integration['id'];
    status: Integration['status'];
    authUrl: string;
    provider: string;
    redirectUri: string;
    clientId: string;
    scope: string;
    state?: string;
    nextRetrievalDate?: string;
}

const formatter = new Intl.DateTimeFormat(navigator.language, {
    hour: 'numeric',
    minute: 'numeric'
});

export const IntegrationActions = ({
    status,
    authUrl,
    provider,
    redirectUri,
    state,
    clientId,
    scope,
    integrationId,
    nextRetrievalDate
}: IntegrationActionsProps) => {
    const translate = useTranslate();
    const [isConfirmSuspendOpen, setIsConfirmSuspendOpen] = useState(false);
    const [isConfirmResumeOpen, setIsConfirmResumeOpen] = useState(false);

    const refresh = useRefresh();
    const [update, { isLoading }] = useUpdate(
        'integrations',
        { id: integrationId },
        { onSuccess: () => refresh() }
    );

    const handleSuspend = () => {
        if (!integrationId) return;
        setIsConfirmSuspendOpen(true);
    };

    const handleResume = () => {
        if (!integrationId) return;
        setIsConfirmResumeOpen(true);
    };

    const handleConfirmSuspend = async () => {
        if (!integrationId) return;

        await update('integrations', {
            id: integrationId,
            data: {
                status: IntegrationStatusEnum.suspended
            }
        });
        setIsConfirmSuspendOpen(false);
    };

    const handleConfirmResume = async () => {
        if (!integrationId) return;

        await update('integrations', {
            id: integrationId,
            data: {
                status: IntegrationStatusEnum.active
            }
        });
        setIsConfirmResumeOpen(false);
    };

    const handleCloseSuspend = () => {
        setIsConfirmSuspendOpen(false);
    };

    const handleCloseResume = () => {
        setIsConfirmResumeOpen(false);
    };

    return (
        <>
            <Stack
                direction="row"
                gap={2}
                justifyContent="space-between"
                alignItems="center"
                sx={{ backgroundColor: '#F5F1EF', borderRadius: 3 }}
                paddingInline={1}
            >
                <Stack direction="row" alignItems="center">
                    <Switch
                        checked={status === 'active'}
                        onChange={
                            status === 'active' ? handleSuspend : handleResume
                        }
                        disabled={status === 'not_connected'}
                    />

                    <Typography
                        fontSize={12}
                        fontWeight={600}
                        color={
                            status === 'active'
                                ? theme.palette.primary.main
                                : theme.palette.grey['600']
                        }
                    >
                        {status === 'active' ? 'ON' : 'OFF'}
                    </Typography>
                </Stack>

                {status === 'active' && (
                    <>
                        {nextRetrievalDate && (
                            <Typography
                                fontSize={11}
                                whiteSpace="nowrap"
                                color={theme.palette.grey['600']}
                            >
                                {translate(
                                    'resources.integrations.fields.next_retrieval_date'
                                )}
                                :{' '}
                                {new Date(nextRetrievalDate) > new Date()
                                    ? formatter.format(
                                          new Date(nextRetrievalDate)
                                      )
                                    : '-'}
                            </Typography>
                        )}

                        <OAuth2Button
                            popup={false}
                            authDialogUrl={authUrl}
                            provider={provider}
                            redirectUri={redirectUri}
                            state={state}
                            clientId={clientId}
                            scope={scope}
                            responseType="code"
                        >
                            <Typography fontSize={8} fontWeight={600}>
                                Edit access
                            </Typography>
                        </OAuth2Button>
                    </>
                )}

                {status === 'not_connected' && (
                    <OAuth2Button
                        popup={false}
                        authDialogUrl={authUrl}
                        provider={provider}
                        redirectUri={redirectUri}
                        state={state}
                        clientId={clientId}
                        scope={scope}
                        responseType="code"
                    >
                        <Button variant="contained" sx={{ width: '100%' }}>
                            <Typography fontSize={8} fontWeight={600}>
                                Connect
                            </Typography>
                        </Button>
                    </OAuth2Button>
                )}

                {status === 'suspended' && (
                    <Typography
                        fontSize={12}
                        whiteSpace="nowrap"
                        color={theme.palette.grey['600']}
                    >
                        Reactivate to start retrieving
                    </Typography>
                )}
            </Stack>

            <Confirm
                isOpen={isConfirmSuspendOpen}
                loading={isLoading}
                title={translate(
                    'resources.integrations.confirm.suspend.message',
                    {
                        provider
                    }
                )}
                content={translate(
                    'resources.integrations.confirm.suspend.description',
                    {
                        provider
                    }
                )}
                onConfirm={handleConfirmSuspend}
                onClose={handleCloseSuspend}
            />

            <Confirm
                isOpen={isConfirmResumeOpen}
                loading={isLoading}
                title={translate(
                    'resources.integrations.confirm.resume.message',
                    {
                        provider
                    }
                )}
                content={translate(
                    'resources.integrations.confirm.resume.description',
                    {
                        provider
                    }
                )}
                onConfirm={handleConfirmResume}
                onClose={handleCloseResume}
            />
        </>
    );
};
