import { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import { acteursFields, acteursFilters } from '../acteurs';
import { organesFields, organesFilters } from '../organes';
import { scrutinsFields, scrutinsFilters } from '../scrutins';
import { dossiersFields, dossiersFilters } from '../dossiers';
import { documentsFields, documentsFilters } from '../documents';
import { questionsFields, questionsFilters } from '../questions';
import { amendementsFields, amendementsFilters } from '../amendements';
import { debatsFields, debatsFilters } from '../debats';
import { interventionsFields, interventionsFilters } from '../interventions';
import { reunionsFields, reunionsFilters } from '../reunions';
import { ListFilter } from './ListFilter';
import { ListNavigation } from './ListNavigation';
import List from './List';
import { Box } from '@mui/material';

export const Search = () => {
    const dataKeys = ['acteurs', 'dossiers', 'amendements', 'scrutins', 'debats', 'interventions', 'reunions', 'documents', 'questions', 'organes']

    const [data, setData] = useState(
        Object.fromEntries(dataKeys.map(k => [k, []]))
    );
    const [pages, setPages] = useState(
        Object.fromEntries(dataKeys.map(k => [k, 1]))
    )
    const [totals, setTotals] = useState(
        Object.fromEntries(dataKeys.map(k => [k, 0]))
    );

    const fields = {
        'acteurs': acteursFields,
        'dossiers': dossiersFields,
        'debats': debatsFields,
        'interventions': interventionsFields,
        'reunions': reunionsFields,
        'documents': documentsFields,
        'scrutins': scrutinsFields,
        'questions': questionsFields,
        'organes': organesFields,
        'amendements': amendementsFields
    };

    const filterMapping = {
        'acteurs': acteursFilters,
        'dossiers': dossiersFilters,
        'reunions': reunionsFilters,
        'debats': debatsFilters,
        'interventions': interventionsFilters,
        'documents': documentsFilters,
        'scrutins': scrutinsFilters,
        'questions': questionsFilters,
        'organes': organesFilters,
        'amendements': amendementsFilters
    };

    function findFirstObjectWithSortKey(obj) {
        for (const key in obj) {
            if (obj[key].hasOwnProperty('sort')) {
                return key;
            }
        }
        return Object.values(obj)[0].field;
    }

    const getDefaultSort = (list) => {
        const listFields = fields[list];
        const firstFieldKey = findFirstObjectWithSortKey(listFields);
        return { field: listFields[firstFieldKey]["sort"] || undefined, order: 'asc', name: firstFieldKey };
    };

    const [sorts, setSorts] = useState(
        Object.fromEntries(dataKeys.map(k => [k, getDefaultSort(k)]))
    );
    const [activeList, setActiveList] = useState('dossiers');
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState({});
    const [search, setSearch] = useState("");
    const [activeFilters, setActiveFilters] = useState(dossiersFilters);
    const dataProvider = useDataProvider();

    const fetchListData = (resource) => {
        const page = pages[resource];

        const includes = Object.values(fields[resource]).reduce((acc, current) => {
            if (current.hasOwnProperty('include')) {
                acc.push(current.include);
            }
            return acc;
        }, []);

        dataProvider.getList(resource, {
            filter: filters,
            pagination: { page: page, perPage: 10 },
            includes: includes,
            sort: sorts[resource]
        })
            .then((res) => {
                if (res) {
                    setData(prevValues => {
                        return { ...prevValues, [resource]: res.data }
                    });
                    setTotals(prevValues => {
                        return { ...prevValues, [resource]: parseInt(res.total, 10) }
                    });
                }
                setLoading(false)
            })
            .catch((error) => {
                console.error(`Failed to fetch ${resource} data:`, error);
                setLoading(false);
            });
    };

    const fetchAllData = () => {
        Object.keys(data).forEach((resource) => {
            fetchListData(resource);
        });
    };

    const fetchTotal = (resource) => {
        dataProvider.getList(resource, { sort: {}, pagination: { page: 1, perPage: 1 } })
            .then((res) => {
                setTotals(prevValues => {
                    return { ...prevValues, [resource]: parseInt(res.total, 10) }
                });
            })
            .catch((error) => console.error(`Failed to fetch ${resource} data:`, error));
    };

    const fetchTotals = () => {
        Object.keys(data).forEach((resource) => {
            fetchTotal(resource);
        });
    };

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        if (Object.keys(filters).length > 0 && activeList) {
            fetchListData(activeList);
        } else {
            fetchAllData();
        }
        fetchTotals();
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [filters, sorts, pages]);

    useEffect(() => {
        setActiveFilters(filterMapping[activeList] || {});
    }, [activeList]);

    const handleFilterChange = (newSearch, newFilters) => {
        setSearch(newSearch);
        setFilters(formatFilter(newFilters));
    };

    const handleSort = (resource, key, field) => {
        const sortField = field.sort || field.field;

        setSorts((prevSort) => {
            const order = prevSort[resource].name === key && prevSort[resource].order === "desc" ? "asc" : "desc";
            return { ...prevSort, [resource]: { field: sortField, order: order, name: key } }
        });
    };

    const formatFilter = (filter) => {
        return Object.fromEntries(
            Object.entries(filter).map(([key, { value }]) => [key, value])
        );
    };

    const handlePageChange = (page) => {
        setActiveList(page);
    };

    const handlePaginationChange = (resource, newPage) => {
        setPages((prevPages) => {
            return { ...prevPages, [resource]: newPage }
        });
    };

    const showList = () => {
        const commonProps = {
            onSort: handleSort,
            resource: activeList,
            loading: loading
        }

        return <List {...commonProps} data={data[activeList]} fields={fields[activeList]} sort={sorts[activeList]} currentPage={pages[activeList]} total={totals[activeList]} onPageChange={(newPage) => handlePaginationChange(activeList, newPage)} />
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '90vh' }}>
            <Box sx={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'white' }}>
                <ListFilter onFilterChange={handleFilterChange} initialFields={activeFilters} />
                <ListNavigation onPageChange={handlePageChange} totals={totals} />
            </Box>
            <Box sx={{ flexGrow: 1, overflow: 'auto', marginRight: "10px" }}>
                {showList()}
            </Box>
        </Box>
    );
};
