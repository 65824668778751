import { formatDate, formatNumber, formatTexteLegislatif, formatActeur } from "../formatters";
import ActeurField from '../components/ActeurField'


export const amendementsFields = {
    'datePublication': { label: "Date de dépôt", field: "datePublication", format: formatDate, sort: "datePublication" },
    'texteLegislatifRef': { label: "Nom du texte", field: "texteLegislatifRef", include: "texteLegislatifRef", format: formatTexteLegislatif },
    'acteurRef': { label: "Auteur", field: "acteurRef", include: "acteurRef", format: formatActeur },
    'divisionTitre': { label: "Article", field: "divisionTitre" },
    'numeroLong': { label: "Numéro", field: "numeroLong", format: formatNumber },
    'sortAmendement': { label: "Sort", field: "sortAmendement" },
    'nombreCoSignataires': { label: "Nombre signataires", field: "nombreCoSignataires", format: formatNumber }
}

export const amendementsFilters = [
    { label: 'UID', value: 'uid', type: 'text' },
];
