import React from 'react';
import ThumbUp from '@mui/icons-material/ThumbUp';
import ThumbDown from '@mui/icons-material/ThumbDown';
import VerifiedIcon from '@mui/icons-material/Verified';
import {
    Button,
    useUpdateMany,
    useNotify,
    useRefresh,
    useUnselectAll,
    useTranslate,
} from 'react-admin';

const BulkStatusButton = ({ resource, resourceName, selectedIds, updateObject, type, approveMessage, rejectMessage, selectMessage, successMessage, failureMessage }) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const unselectAll = useUnselectAll(resourceName || resource);
    const translate = useTranslate();

    const [approve, { isLoading }] = useUpdateMany(
        resource,
        {
          ids: selectedIds,
          data: updateObject
        },
        {
            onSuccess: () => {
                notify(successMessage || translate("resources.moderation_entries.notifications.moderation_success"), { type: 'info' });
                refresh();
                unselectAll();
            },
            onFailure: () => {
                notify(
                    failureMessage || translate("resources.moderation_entries.notifications.moderation_fail"),
                    { type: 'warning' }
                );
            },
        }
    );

    const displayButton = () => {
      switch(type) {
        case "ACCEPT":
          return (
            <Button
              label={approveMessage || translate('pos.actions.accept')}
              onClick={() => approve()}
              disabled={isLoading}
              style={{ margin: '0 10px', color: "#4CAF50" }}
            >
              <ThumbUp
                  color="#4CAF50"
                  style={{ color: '#4CAF50' }}
              />
            </Button>
          );
        case "REJECT":
          return (
            <Button
              label={rejectMessage || translate('pos.actions.reject')}
              onClick={() => approve()}
              disabled={isLoading}
              style={{margin: '0 10px', color: "#F44336"}}
            >
              <ThumbDown 
                color="#F44336"
                style={{ color: '#F44336' }}
              />
            </Button>
          )
        case "SELECT":
          return (
            <Button
              label={translate('pos.actions.select_argument')}
              onClick={() => approve()}
              disabled={isLoading}
              style={{margin: '0 10px', color: "#5B7FF1"}}
            >
              <VerifiedIcon 
                color="#5B7FF1"
                style={{ color: '#5B7FF1' }}
              />
            </Button>
          )
        default:
          return null;
      }
    }

    return (
        <>
          {displayButton()}
        </>
    );
};

export default BulkStatusButton;