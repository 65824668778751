import React from 'react';
import { Box, Chip } from '@mui/material';
import AssembleeIcon from '../icons/AssembleeIcon';
import SenatIcon from '../icons/SenatIcon';

const ChambreField = ({ chambre }) => {
    return (
        <Box display="flex" alignItems="center">
            {chambre === "ASSEMBLEE" || chambre === "AN" ? (
                <Chip
                    icon={<AssembleeIcon />}
                    label="AN"
                    sx={{
                        '& svg': {
                            marginLeft: '12px',
                        },
                    }}
                />
            ) : chambre === "SENAT" || chambre === "SN" ? (
                <Chip
                    icon={<SenatIcon />}
                    label="Senat"
                    sx={{
                        '& svg': {
                            marginLeft: '12px',
                        },
                    }}
                />
            ) : null}
        </Box>
    );
};

export default ChambreField;