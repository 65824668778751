import React, { useState, useEffect, useCallback } from "react";
import {
	useTranslate, 
	useDataProvider,
	Button
} from "react-admin";
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useSettingsContext } from "../../utils/SettingsProvider";
import { flags } from '../../debate/translation_entries/TranslationList';
import { debounce } from 'lodash';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import SectionTitle from "../../utils/SectionTitle";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "15px",
		display: "flex",
        flexDirection: "column"
	},
	header: {
		display: "flex",
        border: "1px solid #5B7FF1",
        borderRadius: "6px",
        background: "#5B7FF122 !important",
        color: "#5B7FF1",
        alignItems: "center",
        padding: "25px",
        width: "max-content"
	},
    stats: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "25px",
        width: "200px",
        whiteSpace: "nowrap"
    },
    statNumber: {
        fontSize: "35px",
        color: "#5B7FF1",
        fontWeight: "bold"
    },
	fullWidth: {
		width: "100%"
	},
	total: {
		marginBottom: "15px"
	},
	success: {
		maxWidth: "max-content"
	},
	successContainer: {
	},
	successButton: {
		marginTop: "15px",
	},
    moderationButton: {
        padding: "15px",
        background: "#5B7FF1 !important",
        color: "#FFF",
        height: "max-content",
        borderRadius: "6px",
        textDecoration: "none",
        display: "flex",
        width: "max-content",
        marginTop: "15px",
        "& span": {
            paddingLeft: "5px"
        }
    },
    actions: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    resetFilters: {
        alignSelf: "center",
        marginTop: "15px",
        marginRight: "15px"
    }
}));


const Moderation = (props) => {
	const [total, setTotal] = useState(0);
	const [available, setAvailable] = useState(0);
    const [reported, setReported] = useState(0);
	const [currentLang, setCurrentLang] = useState("");
    const [groupId, setGroupId] = useState("");
    const [groupOptions, setGroupOptions] = useState([]);
    const [openGroupOptions, setOpenGroupOptions] = useState(false);
    const [groupOptionsInput, setGroupOptionsInput] = useState("");
    const [groupType, setGroupType] = useState("");
    const [moderableType, setModerableType] = useState("");
    const [isReported, setIsReported] = useState("");
    const groupOptionsLoading = openGroupOptions && groupOptions.length === 0;
	const classes = useStyles();
    const settingsContext = useSettingsContext();
	const translate = useTranslate();
	const dataProvider = useDataProvider();

	const fetchModerationOverview = async (lang = currentLang) => {
			setTotal(0);
            const identity = JSON.parse(localStorage.getItem("currentUser"));
			try {
				const response = await dataProvider.getList("moderation_entries", {
					sort: { field: "created_at", order: "DESC" },
					pagination: { page: 1, perPage: 1 },
					filter: { active_entries: identity.id, language: lang, group_type: groupType, moderable_type: moderableType === "Report" ? "" : moderableType, group_id: groupId }
				});
				const total = response.total;
				if (total) {
					setTotal(total);
				}
			} catch (error) { 
				console.log(error);
			}
	}

    const fetchGroupOptions = useCallback(debounce(async (query) => {
        try {
            const response = await dataProvider.getList("groups", {
                sort: { field: "created_at", order: "DESC" },
                pagination: { page: 1, perPage: 10 },
                filter: { 
                    countless: true,
                    ...(query && { query: query})
                }
            });
            setGroupOptions(response.data)
        } catch (error) { 
            console.log(error);
        }
      }, 1000), [])

    const fetchAvailableContents = async (lang = currentLang) => {
        setAvailable(0);
        try {
            const response = await dataProvider.getList("moderation_entries", {
                sort: { field: "created_at", order: "DESC" },
                pagination: { page: 1, perPage: 1 },
                filter: { status: 1, is_locked: false, language: lang, moderable_type: moderableType === "Report" ? "" : moderableType, group_type: groupType, group_id: groupId }
            });
            const total = response.total;
            if (total) {
                setAvailable(total);
            }
        } catch (error) { 
            console.log(error);
        }
    }

    const fetchReportedContent = async (lang = currentLang) => {
        setReported(0);
        try {
            const response = await dataProvider.getList("moderation_entries", {
                sort: { field: "created_at", order: "DESC" },
                pagination: { page: 1, perPage: 1 },
                filter: { is_reported: true, language: lang, moderable_type: moderableType === "Report" ? "" : moderableType, group_type: groupType, group_id: groupId }
            });
            const total = response.total;
            if (total) {
                setReported(total);
            }
        } catch (error) { 
            console.log(error);
        }
    }

    const resetFilters = () => {
        setGroupId("");
        setGroupOptionsInput("");
        setGroupType("")
        setModerableType("");
        setCurrentLang("");
        setIsReported("");
    }

    useEffect(() => {
        if (openGroupOptions) { fetchGroupOptions(); }
    }, [openGroupOptions])

    useEffect(() => {
        setAvailable(0);
        setTotal(0);
        if (moderableType !== "Report") {
            fetchModerationOverview(currentLang);
            fetchAvailableContents(currentLang);
        }
        fetchReportedContent(currentLang);
    }, [currentLang, moderableType, groupType, groupId])

    useEffect(() => {
        if (moderableType === "Comment") {
            setModerableType("Message")
            setGroupType("Source")
        }
        if (moderableType === "Report") {
            setModerableType("Report");
            setIsReported(true);
        }
    }, [moderableType])

	return (
        <>
            <SectionTitle
                title={translate("resources.moderation_entries.label.name")}
            />
            <div className={classes.root}>
                <div className={classes.header}>
                    <div className={classes.stats}>
                        <span className={classes.statNumber}> { total } </span>
                        { translate("resources.moderation_entries.label.content_to_moderate") }
                    </div>
                    <div className={classes.stats}>
                        <span className={classes.statNumber}>  { total - available >= 0 ? total - available : 0 } </span>
                        { translate("resources.moderation_entries.label.content_available") }
                    </div>
                    <div className={classes.stats}>
                        <span className={classes.statNumber}>  { reported } </span>
                        { translate("resources.moderation_entries.label.reported_content", { smart_count: reported }) }
                    </div>
                </div>
                <div className={classes.actions}>
                    { settingsContext?.settings?.translation?.enable === true && settingsContext?.settings?.translation?.translationMethods && Object.keys(settingsContext?.settings?.translation?.translationMethods).length > 0  &&
                        <FormControl sx={{ marginTop: "15px", width: "max-content", minWidth: "200px", borderColor: "#5B7FF1", marginRight: "15px" }}>
                            <InputLabel id="currentLang-label">{ translate("resources.moderation_entries.label.language") }</InputLabel>
                            <Select
                                id="currentLang"
                                value={currentLang}
                                className={classes.selectLang}
                                label="Language"
                                onChange={(e) => setCurrentLang(e.target.value) }
                            >
                                <MenuItem value="">
                                    <em>{ translate("resources.moderation_entries.label.all_languages") }</em>
                                </MenuItem>
                                { Object.keys(settingsContext.settings.translation.translationMethods).map(lang =>
                                    <MenuItem value={lang}>
                                        {lang.toUpperCase()} {flags[lang]}
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    }
                    <FormControl sx={{ marginTop: "15px", width: "max-content", minWidth: "200px", borderColor: "#5B7FF1", marginRight: "15px" }}>
                        <InputLabel id="moderableType-label">{translate("resources.moderation_entries.label.moderable_type")}</InputLabel>
                        <Select
                            id="moderableType"
                            value={moderableType}
                            className={classes.selectLang}
                            label={translate("resources.moderation_entries.label.content_type")}
                            onChange={(e) => setModerableType(e.target.value)}
                        >
                            <MenuItem value="" key='default'>
                                <em>{translate("resources.moderation_entries.label.all_types")}</em>
                            </MenuItem>
                            <MenuItem value={"Message"} key='Message'>
                                { translate("resources.moderation_entries.type.message") }
                            </MenuItem>
                            <MenuItem value={"DebateSuggestion"} key='DebateSuggestion'>
                                { translate("resources.moderation_entries.type.debate_suggestion") }
                            </MenuItem>
                            <MenuItem value={"User"} key='User'>
                                { translate("resources.moderation_entries.type.user") }
                            </MenuItem>
                            <MenuItem value={"Proposal"} key='Proposal'>
                                { translate("resources.moderation_entries.type.proposal") }
                            </MenuItem>
                            <MenuItem value={"Comment"} key='Comment'>
                                { translate("resources.moderation_entries.type.comment") }
                            </MenuItem>
                            <MenuItem value={"Report"} key='Report'>
                                { translate("resources.moderation_entries.type.report") }
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ marginTop: "15px", width: "max-content", minWidth: "200px", borderColor: "#5B7FF1", marginRight: "15px" }}>
                        <Autocomplete
                            freeSolo
                            forcePopupIcon
                            disablePortal
                            disableClearable
                            loading={groupOptionsLoading}
                            options={groupOptions}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} label={translate("resources.moderation_entries.label.debate_filter")} />}
                            open={openGroupOptions}
                            onOpen={() => { setOpenGroupOptions(true); }}
                            onClose={() => { setOpenGroupOptions(false); }}
                            onChange={(event, newValue) => { setGroupId(newValue.identifier); }}
                            inputValue={groupOptionsInput}
                            onInputChange={(event, newInputValue) => { 
                                setGroupOptionsInput(newInputValue);
                                fetchGroupOptions(newInputValue);
                            }}
                        />
                    </FormControl>
                    <Button onClick={resetFilters} className={classes.resetFilters}>
                        <>{ translate("resources.moderation_entries.label.reset_filters") }</>
                    </Button>
                </div>
                <Link to={`/moderation_entries/moderation`} state={{ language: currentLang, moderable_type: moderableType === "Report" ? "" : moderableType, group_type: groupType, group_id: groupId, is_reported: isReported }} className={classes.moderationButton}>
                    { translate("resources.moderation_entries.label.start_moderation") } <span><ArrowForwardIcon /></span>
                </Link>
            </div>
        </>
	);
};

export default Moderation;