import React, { useState } from "react";
import {
	Edit,
	TextInput,
	TabbedForm,
	Labeled,
	DateInput,
	BooleanInput,
	NumberInput,
	FormTab,
	Toolbar,
	useTranslate,
	required
} from "react-admin";
import { useSettingsContext } from "../../utils/SettingsProvider";
import { Box, Alert } from "@mui/material";
import LanguageSelectInput from "../../inputs/LanguageSelectInput";
import MultiSelectInput from "../../inputs/MultiSelectInput";
import ImageUrlInput from "../../inputs/ImageUrlInput";
import LinkArticleInput from '../../inputs/LinkArticleInput';
import HelperText from "../../utils/HelperText";
import EmbedField from "../../inputs/EmbedField";
import CopyLinkField from "../../inputs/CopyLinkField";
import FormSectionTitle from "../../utils/FormSectionTitle";

const validateInput = required();

const ConsultationEdit = (props) => {
	const [resizeIframe, setResizeIframe] = useState(false);
	const translate = useTranslate();
	const config = useSettingsContext();

	const transformData = (data, { previousData }) => {
		const tagObjects = [];

		if (previousData.tag_list !== data.tag_list) { data.tag_list.forEach((tag) => tagObjects.push({ name: tag })) }
		return {
			...(data.origin_image_url !== previousData.origin_image_url && { origin_image_url: data.origin_image_url }),
			...(previousData.tag_list !== data.tag_list && { tag_objects: JSON.stringify(tagObjects) }),
			...(previousData.description_url !== data.description_url && { description_url: data.description_url }),
			...(previousData.description !== data.description && { description: data.description }),
			...(previousData.image_copyright !== data.image_copyright && { image_copyright: data.image_copyright }),
			...(previousData.synthesis_description !== data.synthesis_description && { synthesis_description: data.synthesis_description }),
			...(previousData.content_uids !== data.content_uids && { content_uids: data.content_uids.join(",") }),
			...(previousData.vote_goal !== data.vote_goal && { vote_goal: data.vote_goal }),
			...(previousData.ends_at !== data.ends_at && { ends_at: data.ends_at }),
			...(previousData.language !== data.language && { language: data.language }),
			...(previousData.title !== data.title && { title: data.title }),
			...(previousData.is_pending !== data.is_pending && { is_pending: data.is_pending }),
			...(previousData.tag_id_list !== data.tag_id_list && { tag_id_list: data.tag_id_list.map(item => item.id).join(',') })
		}
	}

	return (
		<Edit
			title={translate("resources.consultations.edit_title")}
			redirect='list'
			mutationMode="pessimistic"
			transform={transformData}
			{...props}
		>
			<TabbedForm toolbar={<Toolbar />}>
				<FormTab label={translate("pos.tabs.main")} onClick={() => setResizeIframe(false)}>
					<CopyLinkField source={'direct_url'} />
					<FormSectionTitle title={translate("resources.consultations.name", { smart_count: 1 })} />
					<TextInput source="title" fullWidth variant="outlined" validate={validateInput} />
					<TextInput source="description" fullWidth variant="outlined" multiline validate={validateInput} />
					<TextInput source="description_url" fullWidth variant="outlined" />
					<TextInput source="synthesis_description" fullWidth variant="outlined" multiline />
					<Box display={{ xs: 'block', sm: 'flex' }} mb={0}>
						<Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
							<DateInput source="ends_at" variant="outlined" />
						</Box>
						<Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
							<NumberInput source="vote_goal" variant="outlined" />
						</Box>
					</Box>
					{config?.settings?.translation?.enable === true &&
						<Box mb={1}>
							<LanguageSelectInput label={translate("resources.settings.fields.language")} />
						</Box>
					}
					<FormSectionTitle title={translate('pos.main.consultation_tags')} />
					<MultiSelectInput
						isCreatable
						isAsync
						source='tag_list'
						initialSource="tags"
						format={(v) => v?.map((e) => {
							return { label: e.display_name, value: e.name };
						})}
						resource={"tags"}
						sort={{ field: "taggings_count", order: "DESC" }}
						label={translate('pos.main.consultation_tags')}
						placeholder={translate("resources.groups.labels.tag_multiselect")}
						helperText={translate("resources.helpers.consultation_tags")}
						noOptionsMessage={translate("resources.groups.labels.no_elements")}
						fullWidth
					/>
					<Alert severity="info">
						{translate("resources.consultations.labels.consultation_tags_info")}
					</Alert>
					<FormSectionTitle title={"image"} />
					<Box flex={1} mt={2}>
						<Labeled label={translate("resources.groups.labels.image")}>
							<ImageUrlInput
								source="origin_image_url"
								initialSource={"image_url"}
								helperText={translate("pos.main.consultation_image_format")}
								errorMessage={translate('resources.consultations.labels.image_error')}
							/>
						</Labeled>
					</Box>
					<Box flex={1} mt={2}>
						<TextInput source="image_copyright" variant="outlined" fullWidth helperText={false} />
						<HelperText text={translate('pos.main.not_required')} />
					</Box>
					{config?.settings?.modules?.sources !== false &&
						<LinkArticleInput
							tagSource={"tag_id_list"}
							tagInitialSource={"content_tags"}
							tagFormatInitial={(initialValue) => initialValue?.map(e => e.tag)}
						/>
					}
				</FormTab>
				<FormTab label={translate("pos.tabs.advanced")} onClick={() => setResizeIframe(true)}>
					<Box flex={1} mt={3}>
						<BooleanInput source="is_pending" label={translate('resources.consultations.fields.is_pending')} />
					</Box>
					<EmbedField type='iframe' resource='consultation' />
					<EmbedField type='oEmbed' resource='consultation' />
				</FormTab>
			</TabbedForm>
		</Edit>
	);
};

export default ConsultationEdit;
