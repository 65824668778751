import { formatChambre, formatOrgane } from "../formatters";

export const debatsFields = {
    'dateSeance': { label: "Date", field: "dateSeance", sort: "dateSeance" },
    'typeAssemblee': { label: "Chambre", field: "typeAssemblee", format: formatChambre },
    'organe': { label: "Organe", field: "organe", format: formatOrgane },
    'sujet': { label: "Sujet", field: "intitule" },
    'resume': { label: 'L\'essentiel', field: "resume"},
};

export const debatsFilters = [
    { label: 'UID', value: 'uid', type: 'text' }
];
