import { AccountCircle } from '@mui/icons-material';
import { Chip, Stack } from '@mui/material';
import { useRecordContext } from 'ra-core';
import React from 'react';

interface AuthorFieldProps {
    label?: string;
}

export default function AuthorField(props: AuthorFieldProps) {
    const record = useRecordContext();

    if (!record) return null;

    const authorName = record['author_name'];

    if (!authorName) return null;

    return (
        <Chip icon={<AccountCircle />} label={authorName} variant="outlined" />
    );
}
