import React from "react";
import { Filter, SearchInput, BooleanInput, TextInput, DateInput, useTranslate } from "react-admin";
import { useSettingsContext } from "../utils/SettingsProvider";
import LanguageSelectInput from "../inputs/LanguageSelectInput";

const UserFilter = (props) => {
	const translate = useTranslate();
	const settingsContext = useSettingsContext();
	return (
		<Filter {...props}>
			<SearchInput source='query' alwaysOn variant={"outlined"} />
			<DateInput source='created_at_gte' alwaysOn label={translate("resources.filters.from_date")} variant={"outlined"} />
			<DateInput source='created_at_lte' alwaysOn label={translate("resources.filters.to_date")} variant={"outlined"} />
			<TextInput source='sub_application_name' label={translate("resources.filters.sub_application_name")} variant={"outlined"} />
			{settingsContext?.settings?.translation?.enable === true &&
				<LanguageSelectInput
					resettable
					alwaysOn
					style={{ marginBottom: "0" }}
				/>
			}
			<TextInput source='uid' alwaysOn variant={"outlined"} />
			<BooleanInput sx={{ padding: "7px 14px", border: "1px solid rgba(0, 0, 0, 0.23)", borderRadius: "4px", marginTop: "8px" }} source='is_expert' alwaysOn label={translate("resources.filters.is_expert")} variant={"outlined"} />
		</Filter>
	);
};

export default UserFilter;
