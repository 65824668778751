import React from "react";
import { Route } from 'react-router-dom';
import { CustomRoutes, Resource } from "react-admin";
import { Moderation, ModerationList } from './moderation_entries';
import { ReportList, ReportIcon } from './reports';

export const routes = [
    <Resource
        name='reports'
        list={ReportList}
        icon={ReportIcon}
    />,
    <CustomRoutes>
        <Route path="/moderation_entries/lobby" element={<Moderation />} />
        <Route path="/moderation_entries/moderation" element={<ModerationList />} />
    </CustomRoutes>
]