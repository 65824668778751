import React, { useState, useEffect } from 'react';
import { useNotify, Notification, useTranslate, useDataProvider, useAuthProvider, useRedirect } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import SSOAuth from './SSOAuth';
import Login from './Login';
import SignUp from './SignUp';
import logo from '../images/logo-responsive.png';

const useStyles = makeStyles(theme => ({
    main: {
        height: "100vh",
        backgroundImage: "url('https://uploads-ssl.webflow.com/58c00327a3a19f2169a348cf/58c387c6b6b941e437becd29_bg-gradient-1.svg'), linear-gradient(135deg, #7956ec, #2fb9f8)",
        backgroundPosition: "center",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    formContainer: {
        boxShadow: "box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
        borderRadius: "4px",
        marginTop: "50px",
        marginBottom: "50px",
        padding: "15px",
        backgroundColor: "white",
        minWidth: "500px"
    },
    header: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "30px",
    },
    logo: {
        width: "80px",
    },
    title: {
        fontSize: "30px",
        fontWeight: "bold",
        color: "#417EC7"
    },
    infoText: {
        color: "#000",
        cursor: "pointer",
        textDecoration: "underline",
        textAlign: "center",
        fontSize: "14px",
        width: "100%",
        "&:hover": {
            color: "#000"
        }
    },
    separationContainer: {
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        fontSize: "12px",
        color: "#000",
        padding: "10px 40px",
        "&::before": {
            content: "''",
            height: "1px",
            display: "block",
            backgroundColor: "#000",
            width: "auto",
            flexGrow: "1",
            marginRight: "30px",
        },
        "&::after": {
            content: "''",
            height: "1px",
            display: "block",
            backgroundColor: "#000",
            width: "auto",
            flexGrow: "1",
            marginLeft: "30px",
        }
    },
    footerLinks: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "10px 40px",
        paddingTop: "25px",
        borderTop: "1px dotted rgba(0, 0, 0, 0.25)"
    },
    ssoContainer: {
        textAlign: "center",
        textDecoration: "underline",
        fontWeight: "bold",
        paddingBottom: "20px",
        cursor: "pointer",
        display: "block",
        color: "#000"
    }
}));

const Auth = () => {
	const [invitationId, setInvitationId] = useState("");
    const [currentAuth, setCurrentAuth] = useState("LOGIN");
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const authProvider = useAuthProvider();
    const dataProvider = useDataProvider();
    const redirectTo = useRedirect();
    const notify = useNotify();
    const translate = useTranslate();

    useEffect(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const invitationId = urlParams.get("invitation_id");
        const existingUser = urlParams.get("existing_user");
        const isSignUp = location.pathname === "/signup" || (invitationId && !existingUser);
        const isSSO = location.pathname === "/sso" ||  urlParams.get("sso_domain");
        if (isSignUp) { setCurrentAuth("SIGNUP") }
        if (isSSO) { setCurrentAuth("SSO") }
		setInvitationId(invitationId);
	}, [location.pathname]);

    const handleInvitation = () => {
        dataProvider.create("application_invitations", {
            invitation_id: invitationId,
        }).then(() => {
            authProvider.getUser().then(() => {
                setIsLoading(false);
                redirectTo("/#");
            });
        }).catch(() => {
            // Erreur mauvais mail 401
            // Rediriger vers onboarding
        })
    }

    const submit = (authParams) => {
        setIsLoading(true);
        authProvider.authenticate(authParams).then(() => {
            if (invitationId) {
                handleInvitation();
            } else { 
                setIsLoading(false);
                redirectTo("/#"); 
            }
        }).catch((error) => {
            setIsLoading(false);
            if (error.response.status === 400) {
                notify(translate("resources.update_password.notifications.error_credentials"), { type: "warning" })
            } else {
                notify(translate("resources.update_password.notifications.error"), { type: "info" })
            }
        });
    };

    const classes = useStyles();
    return (
        <div className={classes.main}>
            <div className={classes.formContainer}>
                <div className={classes.header}>
                    <img className={classes.logo} src={logo} width={150} alt="Logo Logora" />
                    <div className={classes.title}>{ currentAuth === "LOGIN" ? translate("pos.login.login") : currentAuth === "SIGNUP" ? translate("pos.login.signup") : "" }</div>
                </div>
                { currentAuth === "LOGIN" ?
                        <Login isLoading={isLoading} onSubmit={submit} />
                    : currentAuth === "SSO" ?
                        <SSOAuth isLoading={isLoading} onSubmit={submit} />
                    : currentAuth === "SIGNUP" ?
                        <SignUp isLoading={isLoading} onSubmit={submit} />
                    : null
                }
                {   currentAuth !== "SSO"  ?
                        <>
                            <div className={classes.separationContainer}>
                                <div>{translate("pos.login.or")}</div>
                            </div>
                            <a href={"/#/sso"} className={classes.ssoContainer}>
                                { translate("pos.login.use_sso") }
                            </a>
                            <div className={classes.footerLinks}>
                                <a href={currentAuth === "LOGIN" ? "/#/signup" : "/#/login"} className={classes.infoText}>{currentAuth === "LOGIN" ? translate("pos.login.no_account") : translate("pos.login.has_account")}</a>
                                { currentAuth !== "SIGNUP" && <a href="/#/forgot_password" className={classes.infoText}>{translate("pos.login.forgot_password")}</a> }
                            </div>
                        </>
                    :
                        <div className={classes.footerLinks}>
                            <a href={"/#/login"} className={classes.infoText}>{translate("pos.login.more_login")}</a>
                        </div>
                }
            </div>
            <Notification />
        </div>
    );
};

export default Auth;
