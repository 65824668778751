import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDataProvider } from 'react-admin';
import { Box } from '@mui/material';

export const DossierShow = () => {
    const { dossierUid } = useParams();
    const [dossier, setDossier] = useState(null);
    const dataProvider = useDataProvider();

    useEffect(() => {
        fetchElement();
    }, []);

    const fetchElement = useCallback(async () => {
        try {
            const response = await dataProvider.getOne(`dossiers`, {
                id: dossierUid
            });
            const dossier = response.data;
            if (dossier) {
                setDossier(dossier);
            }
        } catch (error) {
            console.log(error);
        }
    }, [dataProvider]);

    if (!dossier) return <div>Loading...</div>;

    return (
        <Box>
            {dossier.uid}
        </Box>
    );
};
