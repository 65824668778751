import ChambreField from "./components/ChambreField"
import ActeurField from "./components/ActeurField"

export const formatDate = (field, item) => {
    return field && (field in item) ? new Date(item[field]).toLocaleDateString() : '-'
}

export const formatCapitalize = (field, item) => {
    if(!(field in item)) {
        return '-'
    }
    return item[field].charAt(0).toUpperCase() + item[field].slice(1)
}

export const formatNumber = (field, item) => {
    return item[field]?.toString() || '0';
}

export const formatChambre = (field, item) => {
    return <ChambreField chambre={item[field]} />
}

export const formatOrgane = (field, item) => {
    return item[field]?.libelle
}

export const formatActeur = (field, item) => {
    return <ActeurField record={item[field]} />
}

export const formatTexteLegislatif = (field, item) => {
    return item[field]?.titre
}