import React, { useState, useEffect } from 'react';
import {
    useTranslate,
    useNotify,
    useDataProvider,
    useRedirect,
    useUpdate
} from 'react-admin';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import StatusButton from '../../utils/StatusButton';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';
import ModerableElement from './ModerableElement';
import UserDetail from './UserDetail';
import AuthorMessages from './AuthorMessages';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import TextField from '@mui/material/TextField';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
import ArgumentSelectButton from '../../debate/arguments/ArgumentSelectButton';

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
    container: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "nowrap",
		justifyContent: "flex-start",
		position: "relative",
		width: "100%",
		marginRight: "50px"
    },
	cardContainer: {
		width: "100%",
	},
	userDetailContainer : {
		width: "100%",
		margin: "25px 0",
	},
	userInfoContainer: {
		width: "100%",
		marginLeft: "25px",
		border: 0,
		background: "#5B7FF122 !important"
	},
	cardActions: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		padding: "0px !important",
		marginBottom: "15px"
	},
	fieldsContainer: {
		marginTop: "15px",
		display: "flex",
		flexDirection: "column"
	},
	textField: {
		marginTop: "10px"
	},
	infoBoxTitle: {
		margin: "0 !important"
	},
	divider: {
		height: "1px",
		borderBottom: "2px dotted #c7c7c7",
		marginBottom: "20px",
		width: "100%"
	},
	moderableHeader: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		maxWidth: "100%",
		marginLeft: "25px !important",
		marginBottom: "25px !important",
		marginTop: "0 !important",
		padding: "15px",
		background: "#5B7FF122 !important",
		borderRadius: "6px",
		boxShadow: "none",
		"&::before" : { display: "none" }
	},
	total: {
		marginBottom: "15px"
	},
	moderableOrigin: {
		marginBottom: "15px"
	},
	closeUserDetail: {
		width: "max-content",
		padding: "3px",
		background: "#e6e6e6",
		borderRadius: "50%",
		display: "flex",
		color: "#666",
		margin: "10px",
		cursor: "pointer",
		float: "right"
	},
	clearChips: {
		display: "inline-flex",
		padding: "3px",
		background: "rgba(0, 0, 0, 0.08)",
		borderRadius: "50%",
		color: "#666",
		cursor: "pointer",
		alignItems: "center",
		position: "relative",
		height: "32px",
		width: "32px",
		justifyContent: "center",
		verticalAlign: "middle",
	},
	actionsContainer: {
		width: "100%",
		maxWidth: "100%",
		marginLeft: "25px",
		marginBottom: "25px",
		padding: "15px",
		background: "#5B7FF122",
		border: "none"
	},
	expandableDetails: {
		maxHeight: "350px",
		overflowY: "scroll",
	},
	expandable: {
		'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
			transform: 'rotate(90deg)',
		}
	},
	help: {
		position: "absolute",
		right: "-12px",
		top: "5px",
		width: "20px",
		color: "#999"
	}
}));

const ModerationStack = (props) => {
    const [moderatorNotes, setModeratorNotes] = useState(
        props.moderationEntry?.moderator_notes
    );
    const classes = useStyles();
    const translate = useTranslate();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const redirectTo = useRedirect();

    useEffect(() => {
        if (props.moderationEntry?.moderator_notes) {
            setModeratorNotes(props.moderationEntry.moderator_notes);
        }
    }, [props.moderationEntry]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const resetInputs = () => {
        setModeratorNotes(undefined);
    };

    const updatedObject = (status) => {
        if (status === 'pass') {
            return {
                ...{ is_locked: false },
                ...{ locked_by: null },
                ...{ locked_at: null }
            };
        }
        return {
            ...{ is_moderated: true },
            ...(status && { status: status }),
            ...(moderatorNotes && { moderator_notes: moderatorNotes }),
            ...(props.moderationEntry?.is_reported && { is_reported: false })
        };
    };

    const handleUpdate = (previousElm) => {
        props.onUpdate(previousElm && previousElm);
        resetInputs();
    };

    const handleKeyPress = (e) => {
        e.stopPropagation();
        if (e.ctrlKey && e.key === 'p') {
            e.preventDefault();
            handleUpdate(true);
        }
    };

    const handleSelect = () => {
        if (props.moderationEntry?.moderable?.score != 99) {
            selectObject();
            props.onUpdateSelection(99);
        } else {
            props.onUpdateSelection(50);
        }
    };

    const [selectObject, { isLoading }] = useUpdate(
        'moderation_entries',
        {
            id: props.moderationEntry?.id,
            data: updatedObject('accepted')
        },
        {
            onSuccess: () => {
                notify(
                    translate(
                        'resources.moderation_entries.notifications.moderation_success'
                    ),
                    { type: 'info' }
                );
                handleUpdate();
            },
            onFailure: () => {
                notify(
                    translate(
                        'resources.moderation_entries.notifications.moderation_fail'
                    ),
                    { type: 'warning' }
                );
            },
            mutationMode: 'optimistic'
        }
    );

    const rejectElement = async (reason) => {
        if (moderatorNotes) {
            props.onUpdateNotes(moderatorNotes);
        }
        props.onUpdateReason(reason);
        await dataProvider
            .update('moderation_entries', {
                id: props.moderationEntry.id,
                data: {
                    is_moderated: true,
                    status: 'rejected',
                    ...(reason && { moderation_reason: reason }),
                    ...(moderatorNotes && { moderator_notes: moderatorNotes }),
                    ...(props.moderationEntry?.is_reported && {
                        is_reported: false
                    })
                }
            })
            .then((res) => {
                if (res?.data?.success) {
                    notify(
                        translate(
                            'resources.moderation_entries.notifications.moderation_success'
                        ),
                        { type: 'info' }
                    );
                    handleUpdate();
                } else {
                    notify(
                        translate(
                            'resources.moderation_entries.notifications.moderation_fail'
                        ),
                        { type: 'warning' }
                    );
                }
            });
    };

    return (
        <>
            <div className={classes.container}>
                {props.isLoading && !props.moderationEntry ? (
                    <CircularProgress />
                ) : !props.isLoading && !props.moderationEntry ? (
                    <div className={classes.successContainer}>
                        <Alert severity="error" className={classes.success}>
                            {translate(
                                'resources.moderation_entries.label.moderation_error'
                            )}
                        </Alert>
                        <Button
                            className={classes.successButton}
                            onClick={() =>
                                redirectTo('/moderation_entries/lobby')
                            }
                        >
                            {translate(
                                'resources.moderation_entries.label.reload'
                            )}
                        </Button>
                    </div>
                ) : (
                    <div style={{ width: '100%', position: 'relative' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                overflowY: 'auto',
                                width: '50%'
                            }}
                        >
                            <Card
                                variant="outlined"
                                className={classes.cardContainer}
                            >
                                <CardContent>
                                    <ModerableElement
                                        moderable={
                                            props.moderationEntry?.moderable
                                        }
                                        moderableType={
                                            props.moderationEntry
                                                ?.moderable_type
                                        }
                                        moderableScore={
                                            props.moderationEntry
                                                ?.moderation_score
                                        }
                                    />
                                </CardContent>
                            </Card>
                            {props.moderationEntry.moderable_type !==
                                'SocialModerator::ModerableContent' && (
                                <Card
                                    variant="outlined"
                                    className={classes.userDetailContainer}
                                >
                                    <Accordion>
                                        <AccordionSummary
                                            className={classes.expandable}
                                            expandIcon={
                                                <KeyboardArrowRightIcon />
                                            }
                                        >
                                            {translate(
                                                'resources.moderation_entries.label.author_messages'
                                            )}
                                        </AccordionSummary>
                                        <AccordionDetails
                                            className={
                                                classes.expandableDetails
                                            }
                                        >
                                            <Box sx={{ overflowY: 'scroll' }}>
                                                <AuthorMessages
                                                    currentEntryId={
                                                        props.moderationEntry
                                                            ?.moderable_type ===
                                                            'Message' &&
                                                        props.moderationEntry
                                                            .moderable.id
                                                    }
                                                    userId={
                                                        props.moderationEntry
                                                            ?.moderable_type ===
                                                        'User'
                                                            ? props
                                                                  .moderationEntry
                                                                  ?.moderable.id
                                                            : props
                                                                  .moderationEntry
                                                                  ?.moderable
                                                                  ?.author?.id
                                                    }
                                                />
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                </Card>
                            )}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'absolute',
                                marginLeft: '50%',
                                top: '0'
                            }}
                        >
                            <Card
                                variant="outlined"
                                className={classes.actionsContainer}
                            >
                                <span className={classes.help}>
                                    <Tooltip
                                        title={translate(
                                            'resources.moderation_entries.label.keyboard_shortcuts'
                                        )}
                                    >
                                        <HelpOutlineIcon />
                                    </Tooltip>
                                </span>
                                <CardActions className={classes.cardActions}>
                                    <StatusButton
                                        resource="moderation_entries"
                                        acceptKey={'a'}
                                        record={props.moderationEntry}
                                        updateObject={updatedObject('accepted')}
                                        type="ACCEPT"
                                        onUpdate={() => handleUpdate()}
                                        noRedirect
                                        {...props}
                                    />
                                    <Button
                                        variant="outlined"
                                        style={{ color: 'rgba(91, 127, 241)' }}
                                        size="small"
                                        onClick={() => handleUpdate(true)}
                                    >
                                        <ArrowBackIcon
                                            color="rgba(91, 127, 241)"
                                            style={{
                                                paddingRight: '0.5em',
                                                color: 'rgba(91, 127, 241)'
                                            }}
                                        />
                                        {translate(
                                            'resources.moderation_entries.label.previous'
                                        )}
                                    </Button>
                                    <StatusButton
                                        resource="moderation_entries"
                                        passKey={'s'}
                                        record={props.moderationEntry}
                                        updateObject={updatedObject('pass')}
                                        type="SKIP"
                                        onUpdate={() => handleUpdate()}
                                        noRedirect
                                        {...props}
                                    />
                                </CardActions>
                                <ArgumentSelectButton
                                    currentRecord={
                                        props.moderationEntry
                                            ?.moderable_type === 'Message' &&
                                        props.moderationEntry.moderable
                                    }
                                    noRedirect
                                    onSelect={handleSelect}
                                />
                                <div className={classes.fieldsContainer}>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            color: '#5B7FF1',
                                            marginBottom: '15px'
                                        }}
                                    >
                                        {translate(
                                            'resources.moderation_entries.label.reject_content'
                                        )}
                                    </Typography>
                                    <FormControl fullWidth>
                                        <div>
                                            <Chip
                                                sx={{
                                                    margin: '5px',
                                                    border:
                                                        props.moderationEntry
                                                            .moderation_reason ===
                                                            'TOXICITY' &&
                                                        '1px solid #666'
                                                }}
                                                onClick={() =>
                                                    rejectElement('TOXICITY')
                                                }
                                                label={translate(
                                                    'resources.moderation_entries.select.toxicity'
                                                )}
                                            />
                                            <Chip
                                                sx={{
                                                    margin: '5px',
                                                    border:
                                                        props.moderationEntry
                                                            .moderation_reason ===
                                                            'OBSCENE' &&
                                                        '1px solid #666'
                                                }}
                                                onClick={() =>
                                                    rejectElement('OBSCENE')
                                                }
                                                label={translate(
                                                    'resources.moderation_entries.select.obscene'
                                                )}
                                            />
                                            <Chip
                                                sx={{
                                                    margin: '5px',
                                                    border:
                                                        props.moderationEntry
                                                            .moderation_reason ===
                                                            'HATE_SPEECH' &&
                                                        '1px solid #666'
                                                }}
                                                onClick={() =>
                                                    rejectElement('HATE_SPEECH')
                                                }
                                                label={translate(
                                                    'resources.moderation_entries.select.hate_speech'
                                                )}
                                            />
                                            <Chip
                                                sx={{
                                                    margin: '5px',
                                                    border:
                                                        props.moderationEntry
                                                            .moderation_reason ===
                                                            'INCOHERENT' &&
                                                        '1px solid #666'
                                                }}
                                                onClick={() =>
                                                    rejectElement('INCOHERENT')
                                                }
                                                label={translate(
                                                    'resources.moderation_entries.select.incoherent'
                                                )}
                                            />
                                            <Chip
                                                sx={{
                                                    margin: '5px',
                                                    border:
                                                        props.moderationEntry
                                                            .moderation_reason ===
                                                            'SPAM' &&
                                                        '1px solid #666'
                                                }}
                                                onClick={() =>
                                                    rejectElement('SPAM')
                                                }
                                                label={translate(
                                                    'resources.moderation_entries.select.spam'
                                                )}
                                            />
                                            <Chip
                                                sx={{
                                                    margin: '5px',
                                                    border:
                                                        props.moderationEntry
                                                            .moderation_reason ===
                                                            'UNSUBSTANTIAL' &&
                                                        '1px solid #666'
                                                }}
                                                onClick={() =>
                                                    rejectElement(
                                                        'UNSUBSTANTIAL'
                                                    )
                                                }
                                                label={translate(
                                                    'resources.moderation_entries.select.unsubstantial'
                                                )}
                                            />
                                            <Chip
                                                sx={{
                                                    margin: '5px',
                                                    background: '#FF000022',
                                                    '&:hover': {
                                                        background:
                                                            '#FF000033 !important'
                                                    }
                                                }}
                                                onClick={() =>
                                                    rejectElement('')
                                                }
                                                label={translate(
                                                    'resources.moderation_entries.select.undefined'
                                                )}
                                            />
                                        </div>
                                    </FormControl>
                                </div>
                                <TextField
                                    sx={{
                                        marginTop: '15px',
                                        background: 'rgba(0, 0, 0, 0.08)'
                                    }}
                                    onChange={(e) =>
                                        setModeratorNotes(e.target.value)
                                    }
                                    value={moderatorNotes || ''}
                                    className={classes.textField}
                                    variant="outlined"
                                    multiline
                                    fullWidth
                                    label={translate(
                                        'resources.moderation_entries.label.entry_notes'
                                    )}
                                />
                            </Card>
                            {props.moderationEntry.moderable_type !==
                                'SocialModerator::ModerableContent' && (
                                <Card
                                    variant="outlined"
                                    className={classes.userInfoContainer}
                                >
                                    <UserDetail entry={props.moderationEntry} />
                                </Card>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ModerationStack;
