// in src/App.js
import React from 'react';
import {
    Admin,
    Resource,
    resolveBrowserLocale,
    combineDataProviders
} from 'react-admin';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { UserList, UserEdit, UserIcon } from './users';
import { SettingsEdit, SettingsIcon } from './settings';
import { ExportIcon, ExportList } from './exports';
import { Dashboard } from './dashboard';
import { IconProvider } from '@logora/debate.icons.icon_provider';
import { useAuthInterceptor } from '@logora/debate.auth.use_auth';
import { axiosClient } from './axiosClient';
import * as regularIcons from '@logora/debate.icons.regular_icons';
import theme from './theme';
import MyLayout from './Layout';
// dataProviders
import authProvider from './authProvider';
// i18n
import polyglotI18nProvider from 'ra-i18n-polyglot';
import locales from './i18n';
import { QueryClientProvider } from './utils/QueryClientProvider';
import { useActiveModulesContext } from './utils/ActiveModulesContext';
// Imported routes
import { routes as debateRoutes, debateDataProvider } from './debate';
import { routes as sourceRoutes } from './source';
import { routes as moderationRoutes } from './moderation';
import { routes as authenticationRoutes, Auth } from './authentication';
import { routes as accountRoutes, accountDataProvider } from './account';
import {
    routes as parliamentRoutes,
    parliamentDataProvider,
    localesParliament
} from './parliament';
import {
    socialNetworksRoutes,
    socialNetworksDataProvider,
    AuthPage
} from './social_networks';

const messages = {
    fr: {
        ...locales.french,
        resources: {
            ...locales.french.resources,
            ...localesParliament.resources
        }
    },
    en: locales.english,
    es: locales.spanish,
    de: locales.german,
    it: locales.italian,
    pt: locales.portuguese,
    pt_BR: locales.portugueseBR
};

const i18nProvider = polyglotI18nProvider(
    (locale) => (messages[locale] ? messages[locale] : messages.en),
    resolveBrowserLocale()
);

const defaultDataProvider = Object.assign(
    debateDataProvider,
    accountDataProvider
);

const dataProvider = combineDataProviders((resource) => {
    if (window.location.hash.includes('parliament')) {
        return parliamentDataProvider;
    } else if (
        resource === 'moderable_contents' ||
        resource === 'integrations' ||
        resource === 'batches' ||
        resource === 'moderable_sources'
    ) {
        return socialNetworksDataProvider;
    } else {
        return defaultDataProvider;
    }
});

const App = () => {
    const {
        isDebateSpaceActive,
        isSourceActive,
        isSocialModerationActive,
        isParliamentActive
    } = useActiveModulesContext();

    const tokenKey =
        process.env.NODE_ENV === 'production'
            ? 'logora_admin_token'
            : 'logora_admin_token_staging';
    useAuthInterceptor(
        axiosClient,
        process.env.REACT_APP_API_AUTH_URL,
        tokenKey
    );

    return (
        <IconProvider library={regularIcons}>
            <BrowserRouter>
                <QueryClientProvider>
                    <Routes>
                        <Route
                            path="/integrations/:provider/callback"
                            element={<AuthPage />}
                        />
                    </Routes>
                </QueryClientProvider>
            </BrowserRouter>

            <Admin
                disableTelemetry
                loginPage={Auth}
                dashboard={Dashboard}
                layout={MyLayout}
                i18nProvider={i18nProvider}
                authProvider={authProvider}
                dataProvider={dataProvider}
                theme={theme}
            >
                {isDebateSpaceActive && (
                    <Resource
                        name="users"
                        list={UserList}
                        edit={UserEdit}
                        icon={UserIcon}
                    />
                )}
                <Resource
                    name="settings"
                    list={SettingsEdit}
                    icon={SettingsIcon}
                />
                <Resource name="exports" list={ExportList} icon={ExportIcon} />
                {isDebateSpaceActive && debateRoutes}
                {isSourceActive && isDebateSpaceActive && sourceRoutes}
                {(isDebateSpaceActive || isSocialModerationActive) &&
                    moderationRoutes}
                {accountRoutes}
                {isParliamentActive && parliamentRoutes}
                {isSocialModerationActive && socialNetworksRoutes}
                {authenticationRoutes}
            </Admin>
        </IconProvider>
    );
};

export default App;
