import React, { useCallback, useRef } from "react";
import classnames from "classnames";
import { List, Datagrid, TextField, DateField, FunctionField } from "react-admin";
import { useNavigate, matchPath, useLocation } from "react-router-dom";
import { Box, Drawer } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { UserReferenceField } from "../../users";
import { useTranslate } from "react-admin";
import ReportEdit from "./ReportEdit";
import ReportFilter from "./ReportFilter";
import ModerationBulkActionButtons from '../../utils/ModerationBulkActionsButtons';
import SectionTitle from "../../utils/SectionTitle";
import CustomPagination from '../../utils/CustomPagination';

const useStyles = makeStyles((theme) => ({
	list: {
		flexGrow: 1
	},
	listWithDrawer: {
		marginRight: 400
	},
	content: {
		maxWidth: "18em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
}));

const ReportList = (props) => {
	const classes = useStyles();
	const translate = useTranslate();
	const navigate = useNavigate();
	const location = useLocation();
	const pageTokens = useRef({});
	const match = matchPath('/reports/:id', location.pathname);
	const handleClose = useCallback(() => {
		navigate('/reports');
	}, [navigate]);

	return (
		<>
			<SectionTitle
				title={translate("resources.reports.section_title")}
				subtitle={translate("resources.reports.section_subtitle")}
				bottomMarged
			/>
			<Box display="flex">
				<List
					className={classnames(classes.list, { [classes.listWithDrawer]: !!match })}
					title={translate("resources.reports.name", { smart_count: 2 })}
					sort={{ field: "created_at", order: "DESC" }}
					filters={<ReportFilter />}
					filter={{ countless: true }}
					disableSyncWithLocation
					pagination={<CustomPagination alwaysOn />}
					queryOptions={{ meta: { pageTokens } }}
					{...props}
				>
					<Datagrid
						rowClick='edit'
						optimized
						bulkActionButtons={<ModerationBulkActionButtons get_moderation_entry={elm => elm.reportable?.moderation_entry?.id} />}
						{...props}
					>
						<UserReferenceField source='author' targetId="author.hash_id" label={translate("resources.reports.labels.author")} />
						<TextField source='reportable_id' label='ID' cellClassName={classes.content} />
						<TextField source='reportable_type' cellClassName={classes.content} label={translate("resources.filters.reportable_type")} />
						<FunctionField
                            render={(record) => {
                                let classificationTranslation;
                                switch (record.classification) {
                                    case "TOXICITY":
                                    classificationTranslation = translate("resources.moderation_entries.select.toxicity");
                                    break;
                                    case "OBSCENE":
									classificationTranslation = translate("resources.moderation_entries.select.obscene");
									break;
                                    case "HATE_SPEECH":
                                    classificationTranslation = translate("resources.moderation_entries.select.hate_speech");
                                    break;
                                    case "INCOHERENT":
                                    classificationTranslation = translate("resources.moderation_entries.select.incoherent");
                                    break;
                                    case "SPAM":
                                    classificationTranslation = translate("resources.moderation_entries.select.spam");
                                    break;
                                    case "UNSUBSTANTIAL":
                                    classificationTranslation = translate("resources.moderation_entries.select.unsubstantial");
                                    break;
                                    default:
                                    classificationTranslation = translate("resources.moderation_entries.select.undefined");
                                }

                                return classificationTranslation;
                            }}
                        />
						<TextField source='description' label={translate("resources.reports.labels.description")} cellClassName={classes.content} />
						<DateField source='created_at' label={translate("resources.reports.labels.created_at")} showTime={true} />
						{/* <FunctionField
							label={translate("resources.reports.labels.status")}
							render={(record) =>
								record.reportable?.moderation_entry?.status === "rejected"
									? translate("resources.messages.status.rejected")
									: record.reportable?.moderation_entry?.status === "accepted"
									? translate("resources.messages.status.accepted")
									: translate("resources.messages.status.pending")
							}
						/> */}
					</Datagrid>
				</List>
				<Drawer
					open={!!match}
					anchor='right'
					onClose={handleClose}
					sx={{ zIndex: 100 }}
				>
					{/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
					{!!match && (
						<ReportEdit
							id={match.params.id}
							onCancel={handleClose}
						/>
					)}
				</Drawer>
			</Box>
		</>
	);
};

export default ReportList;
