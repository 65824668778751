import { formatDate, formatOrgane } from "../formatters";

export const scrutinsFields = {
    dateScrutin: { label: "Date", field: "dateScrutin", sort: "dateScrutin", format: formatDate },
    type: { label: "Type", field: "type" },
    organeRef: { label: "Organe", field: "organeRef", format: formatOrgane },
    titre: { label: "Nom du texte de loi", field: "titre" },
    nombreVotants: { label: "Votes", field: "nombreVotants" }, // calculer avec l'autre champ (votes pour)
    code: { label: "Résultats", field: "code" },
}

export const scrutinsFilters = [
];