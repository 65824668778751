// in src/MyMenu.js
import React from 'react';
import { Menu, useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';

export const ParliamentMenu = () => {
	const translate = useTranslate();
	const location = useLocation();
	const pattern = /(parliament)/;

	return (
		<Menu.Item
			className={pattern.test(location.pathname) && "RaMenuItemLink-active"}
			to="/parliament" 
			primaryText={translate(`resources.parliament.name`)}
			leftIcon={<AccountBalanceOutlinedIcon />}	
		/>
	);
};