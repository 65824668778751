import React from 'react';
import { Box, Avatar, Typography } from '@mui/material';

const ActeurField = ({ record }) => {
    if (!record?.uid ) { return null; }
    const stripPrefix = (userUid) => userUid.replace('PA', '');
    const baseUrl = 'https://tricoteuses.s3.eu-west-3.amazonaws.com/';
    const userImage = `${baseUrl}${stripPrefix(record.uid)}.jpg`;
    const userName = `${record.prenom} ${record.nom}`;

    return (
        <Box display="flex" alignItems="center">
            <Avatar src={userImage} alt={userName} sx={{ width: 35, height: 35, objectFit: 'cover', marginRight: 2 }} />
            <Typography variant="body2">{userName}</Typography>
        </Box>
    );
};

export default ActeurField;