import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Skeleton,
    Stack,
    Typography
} from '@mui/material';
import { useGetOne } from 'react-admin';
import React from 'react';
import { Integration } from './integration';
import { IntegrationStatusChip } from './IntegrationStatusChip';
import { IntegrationActions } from './IntegrationActions';

interface IntegrationCardProps {
    provider: string;
    icon: React.ReactNode;
    authUrl: string;
    redirectUri: string;
    clientId: string;
    scope: string;
    state?: string;
}

export const IntegrationCard = ({
    provider,
    icon,
    authUrl,
    redirectUri,
    clientId,
    state,
    scope
}: IntegrationCardProps) => {
    const { data: integration, isLoading: isIntegrationLoading } =
        useGetOne<Integration>(
            'integrations',
            { id: provider },
            { retry: 1, staleTime: 60 * 1000 }
        );

    return (
        <Card
            variant="outlined"
            sx={{ minWidth: 250, borderRadius: 3, borderColor: '#E8E8E8' }}
        >
            <CardHeader
                title={
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        gap={3}
                    >
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Box
                                width={32}
                                height={32}
                                borderRadius="50%"
                                sx={{
                                    backgroundColor: '#F5F1EF',
                                    display: 'grid',
                                    placeItems: 'center'
                                }}
                            >
                                {icon}
                            </Box>

                            <Typography
                                fontWeight={600}
                                fontSize={12}
                                textTransform="capitalize"
                            >
                                {provider}
                            </Typography>
                        </Stack>

                        {isIntegrationLoading ? (
                            <Skeleton
                                variant="rounded"
                                width={110}
                                height={20}
                                sx={{
                                    margin: 0,
                                    padding: 0,
                                    borderRadius: 100
                                }}
                            />
                        ) : (
                            <IntegrationStatusChip
                                status={integration?.status || 'not_connected'}
                            />
                        )}
                    </Stack>
                }
            />

            <CardContent sx={{ paddingTop: 0 }}>
                {isIntegrationLoading ? (
                    <Skeleton
                        sx={{
                            transform: 'none',
                            height: 38,
                            borderRadius: 3
                        }}
                    />
                ) : (
                    <IntegrationActions
                        integrationId={integration?.id}
                        status={integration?.status || 'not_connected'}
                        authUrl={authUrl}
                        provider={provider}
                        redirectUri={redirectUri}
                        clientId={clientId}
                        state={state}
                        scope={scope}
                        nextRetrievalDate={integration?.next_retrieval_at}
                    />
                )}
            </CardContent>
        </Card>
    );
};
