import { green } from '@mui/material/colors';
import { orange } from '@mui/material/colors';
import { red } from '@mui/material/colors';

const rowStyle = (record, index, defaultStyle = {}) => {
    if (record.moderation_entry?.status === "accepted" || record.status === "accepted" || record.status === 0 || record.reportable?.moderation_entry?.status === "accepted")
        return {
            ...defaultStyle,
            borderRightColor: green[500],
            borderRightWidth: 8,
            borderRightStyle: 'solid',
        };
    if (record.moderation_entry?.status === "pending" || record.reportable?.moderation_entry?.status === "pending" || record.status === 1 || record.status === "pending")
        return {
            ...defaultStyle,
            borderRightColor: orange[500],
            borderRightWidth: 8,
            borderRightStyle: 'solid',
        };
    if (record.moderation_entry?.status === "rejected" || record.status === "rejected" || record.status === 2 || record.reportable?.moderation_entry?.status === "rejected")
        return {
            ...defaultStyle,
            borderRightColor: red[500],
            borderRightWidth: 8,
            borderRightStyle: 'solid',
        };
    return defaultStyle;
};

export default rowStyle;