const formatActe = (field, item) => {
    switch (item.codeActe) {
        case 'AN1':
            return 'Texte déposé à l’Assemblée nationale';
        case 'SN1':
            return 'Texte déposé au Sénat';
        case 'AN1-COM':
            return 'AN : 1ère lecture en commission';
        case 'AN1-DEBATS':
            return 'AN : 1ère lecture en séance publique';
        case 'SN1-COM':
            return 'Sénat : 1ère lecture en commission';
        case 'SN1-DEBATS':
            return 'Sénat : 1ère lecture en séance publique';
        case 'AN2-COM':
            return 'AN : 2ème lecture en commission';
        case 'AN2-DEBATS':
            return 'AN : 2ème lecture en séance publique';
        case 'SN2-COM':
            return 'Sénat : 2ème lecture en commission';
        case 'SN2-DEBATS':
            return 'Sénat : 2ème lecture en séance publique';
        case 'CMP':
            return 'Discussion du texte en commission mixte paritaire';
        case 'CMP-DEBATS-AN':
            return 'AN : Discussion du texte de la CMP en séance publique';
        case 'CMP-DEBATS-SN':
            return 'Sénat : Discussion du texte de la CMP en séance publique';
        case 'CC':
            return 'Saisine du conseil constitutionnel';
        case 'PROM':
            return 'Texte adopté';
        default:
            return '-';
    }
}


export const dossiersFields = {
    'dateActe': { label: "Date de mise à jour", field: "dateActe", sort: "dateActe" },
    'libelleProcedure': { label: "Type", field: "libelleProcedure" },
    'numero': { label: "Numéro", field: "numero" },
    'categorie': { label: "Catégorie", field: "categorie" },
    'titre': { label: "Nom", field: "titre" },
    'acteurRef': { label: "Auteur", field: "acteurRef", include: "acteurRef" },
    'codeActe': { label: "État", field: "codeActe", format: formatActe },
    'resume': { label: "L'essentiel", field: "resume" },
    'textAssocieRef': { label: "Nombre d'amendements", field: "textAssocieRef" }
};

export const dossiersFilters = [
    { label: 'UID', value: 'uid', type: 'text' },
];