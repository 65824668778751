import React from 'react';
import { Stack } from '@mui/material';
import { IntegrationCard } from './IntegrationCard';
import { FacebookRounded, YouTube } from '@mui/icons-material';

const availableIntegrations = [
    {
        icon: <FacebookRounded sx={{ fill: '#1877F2' }} />,
        provider: 'facebook',
        authUrl: 'https://www.facebook.com/v9.0/dialog/oauth',
        clientId: process.env.REACT_APP_FACEBOOK_CLIENT_ID as string,
        scope: 'pages_manage_engagement,pages_read_engagement,pages_read_user_content,pages_manage_metadata,pages_show_list'
    },
    {
        icon: <YouTube sx={{ fill: '#FF0000' }} />,
        provider: 'youtube',
        authUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID as string,
        scope: 'https://www.googleapis.com/auth/youtube.force-ssl'
    }
];

export const IntegrationsList = () => {
    return (
        <Stack direction="row" gap={2} sx={{ marginBlock: 5 }}>
            {availableIntegrations.map((integration) => (
                <IntegrationCard
                    key={integration.provider}
                    provider={integration.provider}
                    icon={integration.icon}
                    authUrl={integration.authUrl}
                    redirectUri={`${process.env.REACT_APP_SOCIAL_MODERATOR_URL}/auth/${integration.provider}/callback`}
                    clientId={integration.clientId}
                    scope={integration.scope}
                    state={`${window.location.origin}/integrations/${integration.provider}/callback`}
                />
            ))}
        </Stack>
    );
};
