import React from 'react';
import {
    Datagrid,
    DateField,
    List,
    NumberField,
    useRecordContext,
    useTranslate
} from 'react-admin';
import { ModerableSourceField } from '../moderable_sources/ModerableSourceField';
import SectionTitle from '../../utils/SectionTitle';
import CustomPagination from '../../utils/CustomPagination';

const AcceptedPercentField = (props: {
    record?: Record<string, any>;
    label?: string;
}) => {
    const record = useRecordContext() || props.record;
    const translate = useTranslate();

    if (!record) return null;

    const accepted = record.accepted_count;
    const total = record.count;

    record['accepted_percent'] = total > 0 ? accepted / total : '-';

    return (
        <NumberField
            source="accepted_percent"
            textAlign="center"
            options={{ style: 'percent' }}
        />
    );
};

export const BatchList = () => {
    const translate = useTranslate();

    return (
        <>
            <SectionTitle
                title={translate('resources.batches.section_title')}
            />

            <List
                resource="batches"
                title=" "
                pagination={<CustomPagination />}
            >
                <Datagrid sx={{ boxShadow: '' }} bulkActionButtons={false}>
                    <DateField
                        source="created_at"
                        label="resources.batches.fields.created_at"
                        showTime
                    />

                    <NumberField
                        source="count"
                        label="resources.batches.fields.count"
                        textAlign="center"
                    />

                    <AcceptedPercentField label="resources.batches.fields.accepted_percent" />

                    <ModerableSourceField label="resources.batches.fields.source" />
                </Datagrid>
            </List>
        </>
    );
};
