import BooleanField from "../components/BooleanField";
import { formatNumber } from "../formatters";

const formatType = (field, item) => {
    if (
        item.codeType === 'COMNL' ||
        item.codeType === 'COMPER' ||
        item.codeType === 'COMSENAT'
    ) {
        return 'Commission permanente';
    } else if (item.codeType === 'CNPE') {
        return "Commission d'enquête";
    } else if (
        item.codeType === 'COMSPSENAT' ||
        item.codeType === 'CNPS' ||
        item.codeType === 'COMNL'
    ) {
        return 'Commission spéciale';
    } else if (
        item.codeType === 'DELEGSENAT' ||
        item.codeType === 'DELEGBUREAU' ||
        item.codeType === 'DELEG'
    ) {
        return 'Délégation';
    } else if (
        item.codeType === 'MISINFO' ||
        item.codeType === 'MISINFOPRE' ||
        item.codeType === 'MISINFOCOM' ||
        item.codeType === 'OFFPAR'
    ) {
        return "Mission d'information";
    } else if (item.codeType === 'CMP') {
        return 'Commission mixte paritaire';
    } else if (item.codeType === 'GA') {
        return "Groupe d'amitié";
    } else if (item.codeType === 'GE' || item.codeType === 'GEVI') {
        return "Groupe d'étude";
    } else if (item.codeType === 'OFFPAR') {
        return 'Office parlementaire';
    } else if (
        item.codeType === 'GP' ||
        item.codeType === 'GROUPESENAT'
    ) {
        return 'Groupe politique';
    } else if (
        item.codeType === 'CONSTITU' ||
        item.codeType === 'CJR' ||
        item.codeType === 'HCJ'
    ) {
        return 'Institution';
    } else if (item.codeType === 'MINISTERE') {
        return 'Ministère';
    } else if (item.codeType === 'GOUVERNEMENT') {
        return 'Gouvernement';
    } else if (
        item.codeType === 'BUREAU' ||
        item.codeType === 'API' ||
        item.codeType === 'PRESREP'
    ) {
        return 'Organe politique';
    } else if (item.codeType === 'ORGEXTPARL') {
        return 'Organe externe';
    } else if (item.codeType === 'AAI') {
        return 'Autorité administrative indépendante';
    } else if (item.codeType === 'ASSEMBLEE') {
        return 'Assemblée';
    } else if (item.codeType === 'CIRCONSCRIPTION') {
        return 'Circonscription';
    } else if (item.codeType === 'PARPOL') {
        return 'Parti politique';
    } else {
        return '-';
    }
}

const formatActif = (field, item) => {
    return <BooleanField condition={!item.dateFin} />
}

export const organesFields = {
    'codeType': { label: 'Type', field: 'codeType', sort: 'codeType', format: formatType },
    'libelle': { label: 'Nom', field: 'libelle', sort: 'libelle' },
    'membres': { label: 'Nombre de membres', field: 'nombreMembres', format: formatNumber },
    'nombreReunionsAnnuelles': { label: 'Nombre de réunions annuelles', field: 'nombreReunionsAnnuelles', format: formatNumber },
    'actif': { label: 'Actif', field: 'dateFin', sort: 'dateFin', format: formatActif },
}

export const organesFilters = [{ label: 'UID', value: 'uid', type: 'text' }];
